import { toast } from "react-toastify";
import { toastResponse } from "../Components/Common/Toast/Toast";
import { HttpStatusCode } from "axios";
import MESSAGE from "../assets/i18n/en.json";

const SUCCESSMESSAGE = MESSAGE.NOTIFICATION;
export const CustomSuccess = (response, config) => {
  if (config?.disableToast === true)
    return {
      ...response.data,
      responseType: "CustomSuccess",
    };
  if (response?.statusCode === HttpStatusCode.Ok){
    toast.success(toastResponse("success",SUCCESSMESSAGE[response?.data?.message] || config?.successMessage));
  }
  return { ...response.data, responseType: "CustomSuccess" };
};
