import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const StripePricingTable = () => {
  const authReducer = useSelector((state) => state.authReducer);
  const pricing_table_id = process.env.REACT_APP_PRICING_TABLE_ID;
  const publishable_key = process.env.REACT_APP_PUBLISHABLE_KEY;
  return (
    <div style={{ margin: "0%", background: "#fff" }}>
      <div className=" text-center w-100  h-100 d-flex flex-column align-items-center justify-content-between">
        <div className="row mb-2 mb-xxl-4 m-9 mt-5">
          <div className="col-md-12">
            <p
              className="flex-grow-1   mt-20 mb-4"
              style={{ fontWeight: 1000, fontSize: "2.75rem" }}
            >
              Choose your right plan!
            </p>
          </div>
          <div className="col-md-12">
            <p className="mb-0 d-flex justify-content-center align-items-center mb-8 fs-5 fs-xxl-6 ">
              <span style={{ width: "49%" }}>
                Select from best plans, ensuring a perfect match. Need more or
                less? Customize your subscription for a seamless fit.
              </span>
            </p>
          </div>
        </div>
        <div />
      </div>
      {/* <Elements stripe={stripePromise}> */}
      <stripe-pricing-table
        pricing-table-id={pricing_table_id}
        publishable-key={publishable_key}
        // client-reference-id={authReducer?.userData?.userName}
        customer-email={authReducer?.userData?.userName}
        // customer-session-client-secret="cuss_secret_RPoYcEF6TRSc2AQIrWybjgf6EAxcutyr8Ljv78JTcaCRsVn"
        // redirect-url="https://yourwebsite.com/success?session_id={CHECKOUT_SESSION_ID}"
        // success-url="https://qa.autopilot.bond.ai/success"
        // cancel-url="https://qa.autopilot.bond.ai/cancel"
      ></stripe-pricing-table>

      {/* </Elements> */}
    </div>
  );
};

export default StripePricingTable;
