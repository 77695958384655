import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostToServer } from "../../../../utils/PostToServer";
import _ from "lodash";
import { convertMappingResponse } from "../../../../utils/Utils";
import {
  setLoaderFalse,
  setLoaderTrue,
  setPerformaLoading,
} from "../../../../Redux/loaderSlice";
import { _post } from "../../../../api/apiClient";
import { HttpStatusCode } from "axios";
import { CustomError } from "../../../../api/CustomError";
import { CustomSuccess } from "../../../../api/CustomSuccess";

const initialState = {
  isFHSReset: false,
  skipAPICall: false,
  skipFHSAPICall: false,
  showGaugeChart: false,
  financialHealthScoreSummary: null,
  financialHealthScoreExpanded: null,
};

export const fetchFinancialHealthScoreSummary = createAsyncThunk(
  `fhs/getFinancialHealthScoreSummary`,
  async ({ orgId, payload }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched FHS successfully.",
        errorMessage: "Failed to fetch FHS!",
      };
      const response = await _post(
        `bank/${orgId}/fhs?card=''&view=summary&page=0&size=10`,
        payload,
      );

      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, { ...config, disableToast: true });
      }
      const { data, error, message, status, statusCode, responseType } =
        CustomSuccess(response, {
          ...config,
          disableToast: true,
        });
      thunkAPI.dispatch(setLoaderFalse());
      return {
        data,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (error) {
      thunkAPI.dispatch(setPerformaLoading(false));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const financialHealthScoreAnalysisSlice = createSlice({
  name: "analysis_financial_health_score",
  initialState,
  reducers: {
    setIsFHSReset: (state, action) => {
      state.isFHSReset = action.payload;
    },
    setSkipAPICall: (state, action) => {
      state.skipAPICall = action.payload;
    },
    setSkipFHSAPICall: (state, action) => {
      state.skipFHSAPICall = action.payload;
    },
    resetFHSSummary: (state) => {
      state.financialHealthScoreSummary = {
        ...state.financialHealthScoreSummary,
        payload: null,
      };
    },
    resetFHSExpanded: (state) => {
      state.financialHealthScoreExpanded = {
        ...state.financialHealthScoreExpanded,
        payload: null,
      };
    },
    setShowGaugeChart: (state, action) => {
      state.showGaugeChart = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinancialHealthScoreSummary.fulfilled, (state, action) => {
        state.financialHealthScoreSummary = {
          ...state.financialHealthScoreSummary,
          payload: action?.payload,
          isLoading: false,
        };
      })
      .addCase(fetchFinancialHealthScoreSummary.pending, (state, action) => {
        state.financialHealthScoreSummary = {
          isLoading: true,
        };
      })
      .addCase(fetchFinancialHealthScoreSummary.rejected, (state, action) => {
        state.financialHealthScoreSummary = {
          isLoading: false,
        };
      });
  },
});

export const selectFinancialHealthScoreSummary = (state) => {
  return {
    payload: _.get(
      state,
      "financialHealthScoreAnalysisReducer.financialHealthScoreSummary.payload.data[0]",
      [],
    ),
    isLoading: _.get(
      state,
      "financialHealthScoreAnalysisReducer.financialHealthScoreSummary.isLoading",
      false,
    ),
  };
};

export const selectIsFHSReset = (state) =>
  state?.financialHealthScoreAnalysisReducer?.isFHSReset;

export const selectSkipAPICall = (state) =>
  state?.financialHealthScoreAnalysisReducer?.skipAPICall;

export const selectSkipFHSAPICall = (state) =>
  state?.financialHealthScoreAnalysisReducer?.skipFHSAPICall;

export const selectShowGaugeChart = (state) =>
  state?.financialHealthScoreAnalysisReducer?.showGaugeChart;

export const {
  setIsFHSReset,
  setSkipAPICall,
  setSkipFHSAPICall,
  setShowGaugeChart,
  resetFHSSummary,
  resetFHSExpanded,
} = financialHealthScoreAnalysisSlice.actions;

export default financialHealthScoreAnalysisSlice.reducer;
