import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _delete, _get, _post, _put } from "../../../../api/apiClient";
import { HttpStatusCode } from "axios";
import { API_STATUS } from "../../sliceUtil";
import { CustomError } from "../../../../api/CustomError";
import { setLoaderFalse, setLoaderTrue } from "../../../../Redux/loaderSlice";
import { CustomSuccess } from "../../../../api/CustomSuccess";
import store from "../../../../app/store";
import { EMAIL_TEMPLATE_TYPE } from "../../../../Components/Bank/CampaignManagement/campaignConstant";

const initEmailTemplatesState = {
  emailTemplates: null,
  isLoading: API_STATUS.STATUS_IDLE,
  totalEmailTemplates: 0,
  templateId: null,
};

const _orgClientId = () => {
  const state = store.getState();
  return state?.orgReducer?.selectedFI?.orgClientId;
};

export const fetchEmailTemplates = createAsyncThunk(
  "templates/fetchTemplates",
  async (
    { paginationObj, templateType, templateStatus, disableLoader },
    thunkAPI,
  ) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched Template successfully.",
        errorMessage: "Failed to fetch Template!",
      };

      const _pageNumber = paginationObj?.currentPage
        ? `PageNumber=${paginationObj?.currentPage}`
        : "";
      const _pageSize =
        paginationObj?.limit > 0 ? `&PageSize=${paginationObj?.limit}` : "";
      const _type = paginationObj?.type ? `&type=${paginationObj?.type}` : "";
      const _status = paginationObj?.selectedStatus
        ? `&status=${paginationObj?.selectedStatus}`
        : "";
      const _sortBy = paginationObj?.sortObj?.sortOrder
        ? `&SortBy=${paginationObj?.sortObj?.sortOrder}`
        : "";
      const _searchBy = paginationObj?.searchData
        ? `&searchBy=${paginationObj?.searchData}`
        : "";
      const _templateType = templateType ? `&type=${templateType}` : "";
      const _templateStatus = templateStatus ? `&status=${templateStatus}` : "";
      const response = await _get(
        `${_orgClientId()}/campaign/template?${_pageNumber}${_pageSize}${_type}${_status}${_searchBy}${_sortBy}${_templateType}${_templateStatus}`,
      );

      if (response?.data?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }

      const {
        data: emailTemplates,
        error,
        message,
        status,
        statusCode,
        responseType,
      } = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });
      thunkAPI.dispatch(setLoaderFalse());
      return {
        emailTemplates,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getEmailTemplateById = createAsyncThunk(
  "users/createEmailTemplate",
  async ({ templateId, disableLoader }, thunkAPI) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Get Template successfully.",
        errorMessage: "Failed to get Template!",
      };
      const response = await _get(
        `${_orgClientId()}/campaign/template/${templateId}`,
      );
      if (response?.data?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const _response = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });
      setTimeout(() => {
        if (disableLoader || disableLoader === undefined)
          thunkAPI.dispatch(setLoaderFalse());
      }, 3000);
      return _response;
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createEmailTemplate = createAsyncThunk(
  "users/createEmailTemplate",
  async ({ templateObj, disableLoader, disableToast }, thunkAPI) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Template created successfully.",
        errorMessage: "Failed to create template.",
        disableToast,
      };
      const response = await _post(
        `${_orgClientId()}/campaign/template`,
        templateObj,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const _response = CustomSuccess(response, {
        ...config,
      });
      thunkAPI.dispatch(setTemplateId(_response?.data?.uuid));
      thunkAPI.dispatch(setLoaderFalse());
      return _response;
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateEmailTemplate = createAsyncThunk(
  "users/createEmailTemplate",
  async (
    { templateId, templateObj, disableToast, disableLoader },
    thunkAPI,
  ) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Template updated successfully.",
        errorMessage: "Failed to update template.",
        disableToast,
      };
      const response = await _put(
        `${_orgClientId()}/campaign/template/${templateId}`,
        templateObj,
        config,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const _response = CustomSuccess(response, {
        ...config,
      });

      thunkAPI.dispatch(setLoaderFalse());
      return _response;
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteEmailTemplate = createAsyncThunk(
  "users/deleteEmailTemplate",
  async ({ paginationObj, templateId, disableLoader }, thunkAPI) => {
    try {
      if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
      const templateType= EMAIL_TEMPLATE_TYPE.CLIENT_CREATED
      const config = {
        successMessage: "Template deleted successfully.",
        errorMessage: "Failed to delete Template!",
      };
      const response = await _delete(
        `${_orgClientId()}/campaign/template/${templateId}`,
        config,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      await thunkAPI.dispatch(fetchEmailTemplates({ paginationObj,templateType }));
      const _response = CustomSuccess(response, {
        ...config,
      });
      thunkAPI.dispatch(setLoaderFalse());
      return _response;
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const emailTemplatesSlice = createSlice({
  name: "emailTemplates",
  initialState: initEmailTemplatesState,
  reducers: {
    setTemplateId: (_state, action) => {
      _state.templateId = action.payload;
    },
    resetTemplateId: (_state) => {
      _state.templateId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailTemplates.pending, () => ({
        isLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(fetchEmailTemplates.fulfilled, (_state, action) => ({
        isLoading: API_STATUS.STATUS_SUCCESS,
        emailTemplates: action?.payload?.emailTemplates?.templates,
        totalEmailTemplates: action?.payload?.emailTemplates?.totalCount,
      }))
      .addCase(fetchEmailTemplates.rejected, (_state, action) => {
        return {
          isLoading: API_STATUS.STATUS_FAILURE,
          message:
            action.error.message || "Error while fetching emailTemplates",
          emailTemplates: [],
        };
      });
  },
});

export const selectEmailTemplates = (_state) => _state?.emailTemplates;
export const selectTemplateId = (_state) => _state?.emailTemplates?.templateId;

export const { setTemplateId, resetTemplateId } = emailTemplatesSlice.actions;
