export const CustomSortIcon = (options) => {
  let icon = options.sorted ? (
    options.sortOrder < 0 ? (
      <DescendingIcon {...options.iconProps} />
    ) : (
      <AscendingIcon {...options.iconProps} />
    )
  ) : (
    <UnsortedIcon {...options.iconProps} />
  );
  return icon;
};

const AscendingIcon = (props) => (
  <div className="pe-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.516"
      height="11"
      viewBox="0 0 7.516 11"
    >
      <g data-name="Group 1274">
        <path
          data-name="Icon ionic-md-arrow-dropup"
          d="M9 17.5l3.758-4 3.758 4z"
          transform="translate(-8.999 -13.5)"
          fill="#03c4de"
        />
        <path
          data-name="Icon ionic-md-arrow-dropup"
          d="M0 4l3.758-4 3.757 4z"
          transform="rotate(180 3.757 5.5)"
          fill="#4b4b4b"
        />
      </g>
    </svg>
  </div>
);

const DescendingIcon = (props) => (
  <div className="pe-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.516"
      height="11"
      viewBox="0 0 7.516 11"
    >
      <g data-name="Group 1274">
        <path
          data-name="Icon ionic-md-arrow-dropup"
          d="M9 17.5l3.758-4 3.758 4z"
          transform="rotate(180 8.258 12.25)"
          fill="#03c4de"
        />
        <path
          data-name="Icon ionic-md-arrow-dropup"
          d="M0 4l3.758-4 3.757 4z"
          transform="translate(.001)"
          fill="#4b4b4b"
        />
      </g>
    </svg>
  </div>
);

const UnsortedIcon = (props) => (
  <div className="pe-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.516"
      height="11"
      viewBox="0 0 7.516 11"
    >
      <g data-name="Group 16427">
        <path
          data-name="Icon ionic-md-arrow-dropup"
          d="M9 17.5l3.758-4 3.758 4z"
          transform="translate(-8.999 -13.5)"
          fill="#4b4b4b"
        />
        <path
          data-name="Icon ionic-md-arrow-dropup"
          d="M0 4l3.758-4 3.757 4z"
          transform="rotate(180 3.757 5.5)"
          fill="#4b4b4b"
        />
      </g>
    </svg>
  </div>
);
