import React, { useState, useEffect, useMemo } from "react";
import "./ConsumersListManagementPage.scss";
import "../themeTable.scss";
import { Link, useSearchParams } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderFalse, setLoaderTrue } from "../../../../Redux/loaderSlice";
import searchLogo from "../../../../assets/images/Search.png";
import { useLocation, useNavigate, useParams } from "react-router";
import exportimages from "../../../../assets/images/exportImages";
import { getFormattedDateType1 } from "../campaignConstant";
import moment from "moment/moment";
import { fetchConsumerListDetails, selectConsumerListDetails } from "../../../../rtk-query/features/campaign/email-campaign/consumerListDetailsSlice";
import { API_STATUS } from "../../../../rtk-query/features/sliceUtil";
import CustomDataTable from "../../../Common/CustomDataTable/CustomDataTable";
import { HttpStatusCode } from "axios";

export const ConsumersDetailsPage = () => {
  const { campaignId, consumerListId } = useParams();
  const [searchParams] = useSearchParams();
  const isCampaignDetail = Boolean(searchParams.get("isCampaignDetail"));
  const [filteredConsumerList, setFilteredConsumerList] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filters] = useState({
    global: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });
  const location = useLocation();
  const consumerListName = location?.state?.consumerListName;
  const [paginationObj, setPaginationObj] = useState({
    currentPage: 1,
    limit: 10,
    totalRecords: "",
    sortObj: { sortField: "", sortOrder: "DESC", sortOrderValue: -1 },
    searchData:""
  });
  const { currentPage, limit, totalRecords, sortObj,searchData } = paginationObj;
  const loader = useSelector((state) => state?.loaderReducer);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const orgReducer = useSelector((state) => state.orgReducer);
  const orgClientId = orgReducer?.selectedFI?.orgClientId;
  const customerListDetailsData = useSelector((state) => selectConsumerListDetails(state));
  // useEffect(() => {
  //   isCampaignDetail ? fetchCampaignConsumerList() : fetchAllConsumerListData();
  // }, []);

  const memoizedPaginationObj = useMemo(
    () => paginationObj,
    [currentPage, limit, sortObj.sortOrder, searchData],
  );

  useEffect(() => {
    const fetchConsumerDetailsWithLoad=async()=>{
      try{
       dispatch(setLoaderTrue())
        const {payload}=await dispatch(fetchConsumerListDetails({orgClientId,campaignId,consumerListId,paginationObj}))
 
      
      }catch(e){
       console.log(e)
      }finally{
       dispatch(setLoaderFalse())
      }
     }
     fetchConsumerDetailsWithLoad()
  }, [memoizedPaginationObj]);

  useEffect(() => {
    if (customerListDetailsData?.isLoading === API_STATUS.STATUS_SUCCESS) {
      updateData();
    }
  }, [customerListDetailsData]);

  const updateData = () => {
    let offset = (currentPage - 1) * paginationObj?.limit;
    setFilteredConsumerList(customerListDetailsData?.consumerListDetails);
    setPaginationObj((prevState) => ({
      ...prevState,
      totalRecords: customerListDetailsData?.totalConsumerList,
    }));
    const newSerialNumbers = customerListDetailsData?.consumerListDetails?.map(
      (item, index) => {
        return offset + index + 1;
      },
    );
    setSerialNumbers(newSerialNumbers);
  };

  // const fetchCampaignConsumerList = async () => {
  //   (async () => {
  //     dispatch(setLoaderTrue());
  //     try {
  //       let offset = (currentPage - 1) * 5;
  //       const response = await GetFromServer(
  //         `/${orgClientId}/campaign/${campaignId}/customer/${consumerListId}?searchby=&limit=10&offset=0&status=&orderby=DESC&field=CREATED_DATE`,
  //         {},
  //         {},
  //         {},
  //       );
  //       if (response.status === 200) {
  //         setFilteredConsumerList(response.data.data?.customers);
  //         setPaginationObj((prevState) => ({
  //           ...prevState,
  //           totalRecords: response?.data?.data?.totalCustomers,
  //         }));
  //         const newSerialNumbers = response?.data?.data?.customers.map(
  //           (item, index) => {
  //             return offset + index + 1;
  //           },
  //         );
  //         setSerialNumbers(newSerialNumbers);
  //       }
  //     } catch (error) {
  //     } finally {
  //       dispatch(setLoaderFalse());
  //     }
  //   })();
  // };



  // const fetchCampaignConsumerByPage = async (
  //   currentpagenumber,
  //   searchData,
  //   limit,
  //   sortValue,
  // ) => {
  //   dispatch(setLoaderTrue());
  //   try {
  //     let offset = (currentpagenumber - 1) * limit;
  //     const response = await GetFromServer(
  //       `/${orgClientId}/campaign/${campaignId}/customer/${consumerListId}?searchby=${
  //         searchData ? encodeURIComponent(searchData) : encodeURIComponent("")
  //       }&limit=${limit}&offset=${offset}&status=&direction=${sortValue}&field=CREATED_DATE`,
  //       {},
  //       {},
  //       {},
  //     );
  //     if (response.status === 200) {
  //       setFilteredConsumerList(response?.data?.data?.customers);
  //       setPaginationObj((prevState) => ({
  //         ...prevState,
  //         currentPage: currentpagenumber,
  //         totalRecords: response?.data?.data?.totalCustomers,
  //       }));
  //       const newSerialNumbers = response?.data?.data?.customers.map(
  //         (item, index) => {
  //           return offset + index + 1;
  //         },
  //       );
  //       setSerialNumbers(newSerialNumbers);
  //     }
  //   } catch (error) {
  //   } finally {
  //     dispatch(setLoaderFalse());
  //   }
  // };

  // const fetchAllConsumerListData = async () => {
  //   (async () => {
  //     dispatch(setLoaderTrue());
  //     try {
  //       let offset = (currentPage - 1) * 5;
  //       const response = await GetFromServer(
  //         `${orgClientId}/campaign/customer/${consumerListId}?searchby=&limit=10&offset=0&status=&orderby=DESC&field=CREATED_DATE`,
  //         {},
  //         {},
  //         {},
  //       );
  //       if (response.status === 200) {
  //         setFilteredConsumerList(response.data.data?.customers);
  //         setPaginationObj((prevState) => ({
  //           ...prevState,
  //           totalRecords: response?.data?.data?.totalCustomers,
  //         }));
  //         const newSerialNumbers = response?.data?.data?.customers.map(
  //           (item, index) => {
  //             return offset + index + 1;
  //           },
  //         );
  //         setSerialNumbers(newSerialNumbers);
  //       }
  //     } catch (error) {
  //     } finally {
  //       dispatch(setLoaderFalse());
  //     }
  //   })();
  // };
  // const fetchConsumerByPage = async (
  //   currentpagenumber,
  //   searchData,
  //   limit,
  //   sortValue,
  // ) => {
  //   dispatch(setLoaderTrue());
  //   try {
  //     let offset = (currentpagenumber - 1) * limit;

  //     const response = await GetFromServer(
  //       `${orgClientId}/campaign/customer/${consumerListId}?searchby=${
  //         searchData ? encodeURIComponent(searchData) : encodeURIComponent("")
  //       }&limit=${limit}&offset=${offset}&status=&direction=${sortValue}&field=CREATED_DATE`,
  //       {},
  //       {},
  //       {},
  //     );
  //     if (response.status === 200) {
  //       setFilteredConsumerList(response?.data?.data?.customers);
  //       setPaginationObj((prevState) => ({
  //         ...prevState,
  //         currentPage: currentpagenumber,
  //         totalRecords: response?.data?.data?.totalCustomers,
  //       }));
  //       const newSerialNumbers = response?.data?.data?.customers.map(
  //         (item, index) => {
  //           return offset + index + 1;
  //         },
  //       );
  //       setSerialNumbers(newSerialNumbers);
  //     }
  //   } catch (error) {
  //   } finally {
  //     dispatch(setLoaderFalse());
  //   }
  // };
 
 
  const emailDateBody = (rowData, index = null) => {
    if (rowData?.emailDate !== null && index !== null) {
      // # Convert string to datetime object
      return (
        <div>
          {rowData?.emailDate !== "NA"
            ? getFormattedDateType1(moment.utc(rowData?.emailDate).format())
            : "NA"}
        </div>
      );
    }
  };

  const emailStatusBody = (rowData) => {
    return (
      <div>
        {rowData?.emailStatus === "NA" ? "Pending" : rowData?.emailStatus}
      </div>
    );
  };

  const srNumberBody = (rowData, index) => {
    return <div className="w-100">{serialNumbers[index?.rowIndex]}</div>;
  };

  return (
    <div
      className="px-10 pt-5 pb-10 contentWrapper dashboardContainer position-relative "
      id="alldata"
    >
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-md-5 col-sm-12  ">
          <div className="custom-theme pageTitle">
            <h3 className="text-dark fs-1 fw-bolder lh-lg mb-1 dashboard-title">
              Consumers List Details
            </h3>
          </div>
          <div
            className="custom-theme pageSubTitle text-868687 "
            style={{ fontSize: "12px" }}
          >
            <span>
              <Link
                id="graph_link_to_report"
                to={`/bank/campaign-management/email-campaigns`}
                style={{ color: "#868687" }}
              >
                Campaigns
              </Link>
              {" > "}
            </span>
            {isCampaignDetail ? (
              <span>
                <Link
                  id="graph_link_to_report"
                  to={`/bank/campaign-management/email-campaigns/${campaignId}`}
                  style={{ color: "#868687" }}
                >
                  Campaign Details
                </Link>
                {" > "}
              </span>
            ) : (
              <span>
                <Link
                  id="graph_link_to_report"
                  to={`/bank/campaign-management/consumers-list`}
                  style={{ color: "#868687" }}
                >
                  Consumers List
                </Link>
                {" > "}
              </span>
            )}
            <span className="text-4b4b4b text-decoration-underline">
              Consumers List Details
            </span>
          </div>
        </div>
        <div
          className="col-md-7 col-sm-12 text-end mb-4"
          style={{ fontWeight: "normal!important" }}
        >
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            onClick={() => {
              navigate(-1);
            }}
            style={{ marginRight: "10px" }}
            className="mr-4 btn btn-outline-primary border-primary btn-bond mb-2 mt-2 px-3 py-3 px-xxl-3 btn-lg fs-5  w-20"
          >
            <img
              alt="back-arrow"
              src={exportimages?.backArrowNew}
              className="w-5px me-2 align-baseline img-fluid arrowImage"
            />
            <img
              alt="back-arrow"
              src={exportimages?.hoverBackArrowNew}
              className="w-5px me-2 align-baseline img-fluid arrowImageHover"
            />
            <span
              className="me-3 fs-5 fs-xxl-6 "
              style={{ fontWeight: "normal" }}
            >
              Back{" "}
            </span>
          </button>
        </div>
      </div>
      <div
        className="row g-0 g-xl-5 g-xxl-8"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="row g-0 g-xl-5 g-xxl-8">
          <div className="col-md-12">
            <div className="mainContainer h-100 px-2">
              <CustomDataTable
              filteredData={filteredConsumerList}
              dataTableState={paginationObj}
              setDataTableState={setPaginationObj}
              searhPlaceholder={"Search using consumer name"}
              showPaginator={true}
              showSearch={true}
              showFilter={false}
              >
                <Column
                  className="ps-8"
                  header="Sr. No."
                  body={srNumberBody}
                  style={{ maxWidth: "6%", padding: "16px" }}
                />

                <Column
                  header="Email Address"
                  field={"emailId"}
                  style={{ maxWidth: "42%" }}
                />

                {!isCampaignDetail && (
                  <Column
                    header="Name"
                    field="name"
                    headerClassName={"ps-5"}
                    style={{ maxWidth: "45%", padding: "16px" }}
                  />
                )}
                {isCampaignDetail && (
                  <Column header="Name" field="name" headerClassName={"ps-5"} />
                )}

                {isCampaignDetail && (
                  <Column
                    header="Email Date"
                    field="emailDate"
                    body={emailDateBody}
                    headerClassName={"ps-2"}
                  />
                )}

                {isCampaignDetail && (
                  <Column
                    header="Email Status"
                    field="emailStatus"
                    body={emailStatusBody}
                    headerClassName={"ps-2"}
                  />
                )}

                {/* <Column
                  header="Email Status"
                  field="emailStatus"
                  body={(rowData) =>
                    rowData.emailStatus ? rowData.emailStatus : "Not Available"
                  }
                  headerClassName={"ps-4"}
                  style={{ maxWidth: "30%" }}
                  sortable
                /> */}
                {/* <Column
                  header="Date"
                  field="emailDate"
                  body={(rowData) =>
                    rowData.emailDate ? rowData.emailDate : "Not Available"
                  }
                  headerClassName={"ps-3"}
                  bodyClassName={"processStatusMainBlock px-1 py-1 pe-4"}
                  style={{ maxWidth: "30%", padding: "16px" }}
                  sortable
                /> */}
              </CustomDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConsumersDetailsPage;
