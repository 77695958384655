import headerIcon from "../../../assets/images/avatar.svg";
import Dropdown from "react-bootstrap/Dropdown";
import "./header.component.scss";
import { useDispatch, useSelector } from "react-redux";
import { LogOut } from "../../../Redux/authSlice";
import {
  setLoaderFalse,
  setLoaderTrue,
  setLogoutLoading,
} from "../../../Redux/loaderSlice";
import { useEffect, useRef, useState } from "react";
import exportImages from "../../../assets/images/exportImages";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { GetFromServer } from "../../../utils/GetFromServer";
import group_3 from "../../../assets/images/in-progress/reseller_bank_img.svg";
import {
  setAllFiList,
  setHealthAnalysisID,
  setOrgId,
  setOrgKey,
  setOrgName,
} from "../../../Redux/orgDetailsSlice";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { resetHID } from "../../../Redux/mainReportSlice";
import { stringToHex } from "../../../utils/Utils";
import ClickOutside from "../ClickOutside";
import ListOptions from "../ListOptions";
import { breadcrumHistory } from "../../../Redux/navigationHistorySlice";
import { UpgradeCardSpecificModal } from "../Popup/UpgradeCardPopUp/UpgradeCard";
import { cardUpgradeData } from "../../../utils/upgradeCardsDetailedInfo";
import InstitutionList from "../InstitutionList/InstitutionList";
import RecursiveCall from "./RecursiveCall";
import { bondScoreAnalysisSlice } from "../../../rtk-query/features/dashboard/bondScore/bondScoreAnalysisSlice";
import {
  setShowGaugeChart,
  setSkipFHSAPICall,
} from "../../../rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";
import { Divider } from "@mui/material";
import { autoPilotLookupsSlice } from "../../../rtk-query/features/campaign/email-campaign/autopilotLookupsSlice";
import useFetch, { RTK_CONSTANT } from "../../../rtk-query/features/useFetch";

const menuItems = [
  {
    label: "Email Templates",
    navUrl: "/bank/campaign-management/email-templates",
  },
  {
    label: "Consumers List",
    navUrl: "/bank/campaign-management/consumers-list",
  },
];

export const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // INITIALIZATION
  const authReducer = useSelector((state) => state.authReducer);
  const userRole = authReducer.userData.role?.toLowerCase();
  const orguid = authReducer.userData.orguid;
  const isFirstLogin = authReducer.userData.isFirstLogin;
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location?.pathname);
  /* eslint-disable-next-line */
  const [bankList, setBankList] = useState([]);
  const [, setAddBankList] = useState([]);
  const [BankNavLink] = useState(false);
  // const [showAddBankModal, setShowBankModal] = useState(false);
  const [toggleOverlay, setToggleOverlay] = useState(false);
  const [upgradeCardModal, setUpgradeCardModal] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState();
  const profileBoxRef = useRef();

  // Merge first name and last name
  const fullName =
    `${authReducer?.userData?.firstName}` +
    " " +
    `${authReducer?.userData?.lastName}`;

  const subscriptionReducer = useSelector((state) => state.subscriptionReducer);

  const isPro = subscriptionReducer?.isPro;
  const isMarketing = subscriptionReducer?.isMarketing;

  useEffect(() => {
    if (
      sessionStorage.getItem("BONDAI_NETWORK_WEB_STORAGE_SELECTED_USER_DATA")
    ) {
      let banklist = JSON.parse(
        sessionStorage.getItem("BONDAI_NETWORK_WEB_STORAGE_SELECTED_USER_DATA"),
      );
      setBankList(banklist);
    }
  }, []);

  // useEffect(() => {
  //   window.history.pushState(null, "");
  //   window.onpopstate = function (event) {
  //     window.history.go(1);
  //   };
  // }, [window]);

  // useEffect(() => {
  //   (async () => {
  //     if (userRole === "reseller" || userRole === "bond-admin") {
  //       // iIF USER ADMIN OR SUPERUSER SHOW THE BANK LIST ON SIDEBAR
  //       // CHECK SESSION STORAGE BEFORE API CALL

  //       await getAddListDetails(userRole, setAddBankList, dispatch);
  //       if (
  //         sessionStorage.getItem(
  //           "BONDAI_NETWORK_WEB_STORAGE_SELECTED_USER_DATA",
  //         )
  //       ) {
  //         let banklist = JSON.parse(
  //           sessionStorage.getItem(
  //             "BONDAI_NETWORK_WEB_STORAGE_SELECTED_USER_DATA",
  //           ),
  //         );
  //         let addBankList = JSON.parse(
  //           sessionStorage.getItem(
  //             "BONDAI_NETWORK_WEB_STORAGE_RESELLER_BANK_LIST",
  //           ),
  //         );
  //         setAddBankList(addBankList);
  //         if (!sessionStorage.getItem("selectedOrgId")) {
  //           let userData = JSON.parse(
  //             sessionStorage.getItem("userData") ?? "[]",
  //           );
  //           let hexOfUsername = stringToHex(userData.userName).toString();
  //           if (localStorage.getItem(hexOfUsername)) {
  //             let bankselected = banklist.find(
  //               (item) => item.orguid === localStorage.getItem(hexOfUsername),
  //             );
  //             // console.log(bankselected, localStorage.getItem(hexOfUsername))
  //             if (
  //               bankselected?.isRevokeAccess === true &&
  //               bankselected?.disabled === false
  //             ) {
  //               dispatch(setOrgId(bankselected.orguid));
  //               dispatch(setOrgName(bankselected.org_name));
  //             } else {
  //               const firstValidBank = banklist?.filter(
  //                 (item) => item?.isRevokeAccess === true,
  //               );
  //               dispatch(setOrgId(firstValidBank[0].orguid));
  //               dispatch(setOrgName(firstValidBank[0].org_name));

  //               dispatch(setLoaderFalse());
  //             }
  //           } else {
  //             const firstValidBank = banklist.filter(
  //               (item) => item.isRevokeAccess === true,
  //             );
  //             dispatch(setOrgId(firstValidBank[0].orguid));
  //             dispatch(setOrgName(firstValidBank[0].org_name));

  //             dispatch(setLoaderFalse());
  //           }
  //         }
  //         setBankList(banklist);
  //       }

  //       // IF NOT IN SESSION STORAGE GET IT FROM API
  //       else {
  //         await getBankDetails(
  //           orguid,
  //           userRole,
  //           setBankList,
  //           dispatch,
  //           authReducer,
  //         );
  //         // await getAddListDetails(userRole, setAddBankList, dispatch);
  //       }
  //     } else if (
  //       userRole === "admin" &&
  //       authReducer?.userData?.isDummyDashborad
  //     ) {
  //       if (
  //         sessionStorage.getItem(
  //           "BONDAI_NETWORK_WEB_STORAGE_SELECTED_USER_DATA",
  //         )
  //       ) {
  //         let banklist = JSON.parse(
  //           sessionStorage.getItem(
  //             "BONDAI_NETWORK_WEB_STORAGE_SELECTED_USER_DATA",
  //           ),
  //         );
  //         setBankList(banklist);
  //       } else {
  //         await getBankDetails(
  //           orguid,
  //           userRole,
  //           setBankList,
  //           dispatch,
  //           authReducer,
  //         );
  //       }
  //     } else {
  //       // IF NOT ADMIN SET THE AUTH REDUCER
  //       if (
  //         userRole === "user" ||
  //         (userRole === "admin" && !authReducer?.userData?.isDummyDashborad) ||
  //         userRole === "content" ||
  //         userRole === "Content" ||
  //         authReducer.userData.role === "User"
  //       ) {
  //         dispatch(setOrgId(authReducer.userData.orguid));
  //         dispatch(setOrgName(authReducer.userData.orgnization_name));
  //       }

  //       dispatch(setLoaderFalse());
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    // CHECKING THE ACTIVE NAVLINK AS BANK?
    if (BankNavLink === true) {
      setCurrentPath("none");
    } else {
      setCurrentPath(location.pathname);
    }
  });

  useEffect(() => {
    if (isFirstLogin && !authReducer?.userContactedSales) {
      const selectedCard = cardUpgradeData.find(
        (card) => card.name === "first_time_logged_in",
      );
      setUpgradeCardModal(true);
      setSelectedCardData(selectedCard);
    }
    // eslint-disable-next-line
  }, []);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
  };

  useEffect(() => {
    const imgs = [
      exportImages.missed_revenue_upgrade,
      exportImages.financial_health_score_upgrade,
      exportImages.uplift_loans_upgrade,
      exportImages.boost_deposits_upgrade,
      exportImages.beat_the_competition_upgrade,
      exportImages.automated_marketing_upgrade,
      exportImages.contact_sales_team_upgrade,
      exportImages.contact_sales_team_success_upgrade,
      exportImages.consumer_behavior_upgrade,
      group_3,
      exportImages.mobile_static_img,
      exportImages.power_button,
      exportImages.power_button_blue,
      exportImages.icons_privacy_policy,
      exportImages.icons_privacy_policy_blue,
      exportImages.icons_settings,
      exportImages.icons_settings_bule,
      exportImages?.icon_role_star,
      exportImages.list_icon_view_details,
      exportImages.list_icon_send_test_mail,
      exportImages.list_icon_approve,
      exportImages.list_icon_eye,
      exportImages.list_icon_trash,
      exportImages.list_icon_edit_grey,
      exportImages.list_icon_speed_test,
      exportImages.list_icon_edit,
      exportImages.list_icon_delete,
      exportImages.speedtest,
      exportImages.gauge_blue,
      exportImages.gauge_white,
      exportImages.trend_blue,
      exportImages.trend_white,
    ];
    cacheImages(imgs);
  }, []);
  const toggleButton = (
    <div className="d-flex">
      <div className="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6">
        <span className="svg-icon svg-icon-1 svg-icon-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
              fill="black"
            />
            <path
              opacity="0.3"
              d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        {/* <Dropdown className="userIconDropdown" >
                    <Dropdown.Toggle className='btn btn-icon btn-sm btn-active-bg-accent ms-1 ms-lg-6' type="image" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="true" >
                        <img alt="icon" src={headerIcon} className="w-100 userIcon" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className=" p-0 w-125px w-xxl-150px" aria-labelledby="dropdownMenuButton1" >

                        <Dropdown.Item onClick={() => navigate("/pages/settings")}>
                            <div className="dropdown-item cursor-pointer" >
                                <span>Settings  </span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item className='p-0 w-100' href="https://www.bond.ai/privacy.html#privacypolicy" target="_blank">
                            <div className="p-0 cursor-pointer w-100 h-100" >
                                <span className='privacy-icon w-100 h-100' style={{ padding: '10px 18px 10px 18px', boxSizing: 'border-box' }}>
                                    <span style={{ marginLeft: "2.5rem" }}>Privacy Policy</span>
                                </span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <div className="dropdown-item cursor-pointer" onClick={() => LogoutHandler()}>
                                <span>Logout</span>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
      </div>
    </div>
  );

  const changeNavLink = () => {
    setCurrentPath(location.pathname);
    props.setNavBar(false);
  };
  // LOGOUT HANDLER
  const LogoutHandler = async () => {
    dispatch(setSkipFHSAPICall(true));
    dispatch(setLogoutLoading(true));
    dispatch(setLoaderTrue());
    dispatch(setOrgId(""));
    dispatch(setOrgName(""));
    dispatch(setOrgKey(""));
    dispatch(resetHID());
    dispatch(setHealthAnalysisID(null));
    await dispatch(LogOut())
      .unwrap()
      .then(() => {
        dispatch(setLoaderFalse());
      })
      .catch((err) => {
        dispatch(setLoaderFalse());
      });
    dispatch(bondScoreAnalysisSlice.util.resetApiState());
    //Added this is to remove the dynamically added Bond_Component.js file.
    const scriptElement = document.querySelector(
      'script[src="./Bond_Component.js"]',
    );
    scriptElement?.remove();
    localStorage.clear();
  };

  const [expanded, setExpanded] = useState(false); // Use state to toggle Navbar expansion
  const orgReducer = useSelector((state) => state.orgReducer);
  const noBankListData = orgReducer.selectedFI === null ? true : false;
  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  const handleEmailCampaignsMenuClick = (event, selectedMenu) => {
    event.preventDefault();
    navigate(selectedMenu?.navUrl);
    setToggleOverlay(false);
  };
  return (
    <>
      {/* eslint-disable */}
      <div className="px-10 d-flex align-items-stretch justify-content-between w-100 fixed-header bond-header">
        <RecursiveCall />
        <Navbar
          expand="lg"
          className=" w-100 fixed-header"
          style={{ backgroundColor: "#ffffff", padding: "0px" }}
        >
          <Container
            fluid
            style={{
              margin: "0px",
              padding: "0px",
              backgroundColor: "#ffffff",
            }}
          >
            <Navbar.Brand>
              <div className="aside-logo pe-2">
                <img alt="Logo" src={exportImages?.logo_black_img} />
              </div>
            </Navbar.Brand>
            <Divider
              className="vertical"
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0 d-flex align-items-center nav-header"
                style={{ maxHeight: "100px" }}
                navbarScroll
                overlay={"true"}
              >
                <Nav.Link
                // style={{
                //   display: !noBankListData ? "block" : "none",
                // }}
                >
                  <NavLink
                    to={
                      userRole === "reseller" || userRole === "bond-admin"
                        ? `/user/bankperformancemetrics`
                        : `/bank/health-analysis/report/${
                            orgReducer?.healthAnalysisID?.uid
                              ? orgReducer?.healthAnalysisID?.uid
                              : "no-analysis-id"
                          }`
                    }
                    className="menu-item-header"
                    onClick={() => changeNavLink()}
                  >
                    <div
                      className={
                        currentPath.includes("/bank/health-analysis/report/") ||
                        currentPath.includes("/user/dashboard") ||
                        currentPath.includes("/user/bankperformancemetrics")
                          ? " menu-link  active-link py-2  "
                          : "menu-link py-2 "
                      }
                    >
                      <span className="menu-title">Home</span>
                    </div>
                  </NavLink>
                </Nav.Link>
                {/* <Nav.Link
                  style={{
                    display:
                      (userRole === "user" ||
                        userRole === "admin" ||
                        userRole === "reseller" ||
                        userRole === "bond-admin") &&
                      (isPro || isMarketing) &&
                      !noBankListData
                        ? "block"
                        : "none",
                  }}
                  s
                >
                  <NavLink
                    to={"/pages/stepone"}
                    className="menu-item-header"
                    onClick={() => changeNavLink()}
                  >
                    <div
                      className={
                        currentPath.includes("/pages/stepone") ||
                        currentPath.includes("/pages/steptwo") ||
                        currentPath.includes("/pages/stepthree")
                          ? " menu-link  active-link py-2  "
                          : "menu-link py-2 "
                      }
                    >
                      <span className="menu-title">File Upload</span>
                    </div>
                  </NavLink>
                </Nav.Link> */}

                {/* <Nav.Link
                  style={{
                    display:
                      (userRole === "user" ||
                        userRole === "admin" ||
                        userRole === "reseller" ||
                        userRole === "bond-admin") &&
                        (isPro || isMarketing) &&
                        !noBankListData
                        ? "block"
                        : "none",
                  }}
                >
                  <NavLink
                    to={"/bank/health-analysis/history"}
                    className="menu-item-header"
                    onClick={() => changeNavLink()}
                  >
                    <div
                      className={
                        currentPath === "/bank/health-analysis/history"
                          ? " menu-link  active-link py-2  "
                          : "menu-link py-2 "
                      }
                    >
                      <span className="menu-title">History</span>
                    </div>
                  </NavLink>
                </Nav.Link> */}

                <Nav.Link
                  style={{
                    width: 0,
                    paddingLeft: 0,
                    paddingRight: "1.1rem",
                    margin: 0,
                    display: "inline-grid",
                    alignItems: "center",
                    marginTop: "-0.5rem",
                    display:
                      authReducer?.userData?.role !== "Bond-Admin" ||
                      authReducer?.userData?.role !== "Reseller"
                        ? "none"
                        : "block",
                  }}
                >
                  <NavLink
                    onClick={(e) => {
                      e.preventDefault();
                      const selectedCard = cardUpgradeData.find(
                        (card) => card.name === "upgrade_email_campaign",
                      );
                      setUpgradeCardModal(true);
                      setSelectedCardData(selectedCard);
                    }}
                  >
                    <img
                      alt="icon"
                      width="20"
                      height="20"
                      src={exportImages.dashboard_upgrade_lock}
                      className="img-fluid-header"
                      style={{
                        display: isPro
                          ? isMarketing
                            ? "none"
                            : "block"
                          : "none",
                      }}
                    />
                  </NavLink>
                </Nav.Link>

                {userRole !== "reseller" && (
                  <Nav.Link
                    style={{
                      display:
                        isPro && isMarketing && !noBankListData
                          ? "block"
                          : "none",
                    }}
                  >
                    <NavLink
                      to={"/bank/campaign-management/email-campaigns"}
                      className="menu-item-header"
                      onMouseEnter={() => setToggleOverlay(true)} // Add this line
                      onMouseLeave={() => setToggleOverlay(false)}
                    >
                      <div
                        className={
                          currentPath.includes("campaign-management")
                            ? " menu-link active-link py-2  "
                            : "menu-link py-2 "
                        }
                      >
                        <span className="menu-title">
                          &nbsp;&nbsp;&nbsp;Campaigns&nbsp;&nbsp;&nbsp;
                        </span>

                        {toggleOverlay && (
                          <ClickOutside
                            onClick={() => {
                              setToggleOverlay(false);
                              dispatch(
                                breadcrumHistory({
                                  cardName: "",
                                  currentPath: "",
                                  checkPathFromCluster: false,
                                  hid: orgReducer?.healthAnalysisID?.uid
                                    ? orgReducer?.healthAnalysisID?.uid
                                    : "",
                                }),
                              );
                            }}
                          >
                            <div className="overlayPanel py-0 px-0">
                              <ListOptions
                                options={menuItems}
                                onClick={handleEmailCampaignsMenuClick}
                              />
                            </div>
                          </ClickOutside>
                        )}
                      </div>
                    </NavLink>
                  </Nav.Link>
                )}

                {!(userRole === "reseller" || userRole === "bond-admin") && (
                  <Nav.Link>
                    <NavLink
                      to={"/bank/user-management/user-management-list"}
                      className="menu-item-header"
                      onClick={() => changeNavLink()}
                    >
                      <div
                        className={
                          currentPath ===
                          "/bank/user-management/user-management-list"
                            ? " menu-link active-link py-2  "
                            : "menu-link py-2 "
                        }
                      >
                        <span className="menu-title">Users</span>
                      </div>
                    </NavLink>
                  </Nav.Link>
                )}
                <Nav.Link>
                  <NavLink
                    to={"/pages/faq"}
                    className="menu-item-header"
                    onClick={() => changeNavLink()}
                  >
                    <div
                      className={
                        currentPath === "/pages/faq"
                          ? " menu-link active-link py-2  "
                          : "menu-link py-2 "
                      }
                    >
                      <span className="menu-title">Support</span>
                    </div>
                  </NavLink>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {/* {(userRole === "reseller" || userRole === "bond-admin") && (
              <InstitutionList profileBoxRef={profileBoxRef} />
            )} */}

            <Navbar.Toggle
              aria-controls={`navbarScroll`}
              children={toggleButton}
              style={{
                alignSelf: "end",
                textAlign: "left",
                marginLeft: "auto",
              }}
              onClick={toggleNavbar}
            />

            <div className="d-flex align-items-center ">
              <div
                style={{ display: "none" }}
                className="dropdown notificationDropdown mx-5"
              >
                <button
                  className="btn btn-icon btn-sm btn-active-bg-accent badge rounded-pill bg-light position-relative"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img alt="icon" src={exportImages?.notification} />
                  <span className="position-absolute badge-number top-0 start-100 translate-middle p-2 btn-bond bg-success border border-light rounded-circle">
                    <span></span>
                  </span>
                </button>

                <ul
                  className="dropdown-menu p-0 w-300px w-xxl-400px"
                  aria-labelledby="dropdownMenuButton2"
                >
                  <li className="dropdownTitle"> New Notifications </li>
                  <li>
                    <hr className="dropdown-divider m-0" />
                  </li>
                  <div className="notificationData">
                    <li>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 w-35px w-xxl-50px">
                          <img
                            src="assets/images/icon_notification_bank_health_score.png"
                            className="img-fluid"
                            alt="icon_notification_bank_health_score.png"
                          />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <label className="title"></label>
                          <p className="mb-0 subTitle"></p>
                        </div>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
              <Dropdown className="userIconDropdown  d-block d-sm-block d-md-block d-lg-block  ">
                <Dropdown.Toggle
                  className="btn btn-icon btn-sm btn-active-bg-accent ms-1 ms-lg-6"
                  type="image"
                  id="dropdownMenuButton1"
                  ref={profileBoxRef}
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img alt="icon" src={headerIcon} className="w-100 userIcon" />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className=" p-0 user-dropdown"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <div
                    className="rightArrow"
                    style={{
                      display: "block",
                      border: "none",
                      top: "-6px",
                      right: "28px",
                    }}
                  ></div>
                  <Dropdown.ItemText>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <img
                        alt="icon"
                        src={headerIcon}
                        className=" user-avtar mb-3"
                      />
                      <span
                        className="fw-bolder text-wrap"
                        style={{ fontSize: "16px" }}
                        title={fullName}
                      >
                        {fullName}
                      </span>
                      <span
                        className=" text-steel text-wrap"
                        style={{ fontSize: "12px" }}
                        title={authReducer?.userData?.userName}
                      >
                        {" "}
                        {authReducer?.userData?.userName}
                      </span>
                      <div
                        style={{ color: "#6b7de5", marginTop: "5px" }}
                        className="d-flex justify-content-center h-100"
                      >
                        <img
                          src={exportImages?.icon_role_star}
                          style={{
                            marginRight: "6px",
                            height: "16px",
                            width: "16px",
                          }}
                        />
                        <span className="">{authReducer?.userData?.role} </span>
                        {/* Super Admin */}
                      </div>
                    </div>
                  </Dropdown.ItemText>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="p-0 d-flex w-100 "
                    onClick={() => navigate("/pages/settings")}
                  >
                    <div className="p-0 cursor-pointer d-flex w-100 h-100">
                      <span
                        className="setting-icon w-100 h-100"
                        style={{ padding: "10px 18px 10px 18px" }}
                      >
                        <span
                          className=" w-100 h-100"
                          style={{ marginLeft: "2.5rem" }}
                        >
                          Settings
                        </span>
                      </span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-0 d-flex w-100 d-none ">
                    <div className="p-0 cursor-pointer d-flex w-100 h-100">
                      <span
                        className="privacy-icon w-100 h-100"
                        style={{ margin: "10px 18px 10px 18px!important" }}
                      >
                        <span
                          className=" w-100 h-100"
                          style={{ marginLeft: "2.5rem" }}
                        >
                          Privacy Policy
                        </span>
                      </span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="p-0 w-100"
                    href="https://www.bond.ai/terms-conditions#privacy-policy"
                    target="_blank"
                  >
                    <div className="p-0 cursor-pointer w-100 h-100">
                      <span
                        className="privacy-icon w-100 h-100"
                        style={{
                          padding: "10px 18px 10px 18px",
                          boxSizing: "border-box",
                        }}
                      >
                        <span style={{ marginLeft: "2.5rem" }}>
                          Privacy Policy
                        </span>
                      </span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="p-0 w-100"
                    onClick={() => navigate(`/user/invoice`)}
                  >
                    <div className="p-0 cursor-pointer w-100 h-100">
                      <span
                        className="privacy-icon w-100 h-100"
                        style={{
                          padding: "10px 18px 10px 18px",
                          boxSizing: "border-box",
                        }}
                      >
                        <span style={{ marginLeft: "2.5rem" }}>
                          Billing Information
                        </span>
                      </span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-0 w-100">
                    <div
                      className="p-0 cursor-pointer w-100 h-100"
                      onClick={() => LogoutHandler()}
                    >
                      <span
                        className="power-icon w-100 h-100"
                        style={{
                          padding: "10px 18px 10px 18px",
                          boxSizing: "border-box",
                        }}
                      >
                        <span style={{ marginLeft: "2.5rem" }}>Logout</span>
                      </span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </Navbar>
      </div>
      <UpgradeCardSpecificModal
        show={upgradeCardModal}
        onHide={() => setUpgradeCardModal(false)}
        cardData={selectedCardData}
      />
    </>
  );
};

export async function getAddListDetails(userRole, setAddBankList, dispatch) {
  try {
    if (
      !sessionStorage.getItem("BONDAI_NETWORK_WEB_STORAGE_RESELLER_BANK_LIST")
    ) {
      const response = await GetFromServer(
        "/organization/bank/reseller",
        {},
        {},
        { role: userRole },
      );
      let addbanklist = response.data.data;
      addbanklist = addbanklist.map((i) => ({
        ...i,
        org_name: i.displayName,
        compare_name: i.displayName,
        name: i.displayName,
        code: i.orgKey,
      }));
      setAddBankList(addbanklist);
      dispatch(setAllFiList(addbanklist));
      sessionStorage.setItem(
        "BONDAI_NETWORK_WEB_STORAGE_RESELLER_BANK_LIST",
        JSON.stringify(addbanklist),
      );
    }
    // SETTING THE REDUCER
  } catch (error) {
    dispatch(setLoaderFalse());
  }
}

export async function getBankDetails(
  orguid,
  userRole,
  setBankList,
  dispatch,
  authReducer,
) {
  try {
    dispatch(setLoaderTrue());
    if ((authReducer?.banklist).length !== 0) {
      const response = await GetFromServer(
        "/bank/healthanalysis/getchild_orglist",
        {},
        {},
        {
          orguid: orguid,
          role: userRole,
          ParentOrgName: authReducer?.userData?.orgnization_name,
        },
      );
      let banklist = response.data.data;
      banklist = banklist.sort((a, b) => a.org_name.localeCompare(b.org_name));
      if (banklist.length !== 0) {
        banklist = banklist.map((i) => ({
          ...i,
          compare_name: i.org_name,
        }));
        banklist = banklist.map((item) => {
          if (item.isRevokeAccess) {
            return { ...item, disabled: false };
          } else {
            return { ...item, disabled: true };
          }
        });
        setBankList(userRole == "admin" ? response.data.data : banklist);
        sessionStorage.setItem(
          "BONDAI_NETWORK_WEB_STORAGE_SELECTED_USER_DATA",
          JSON.stringify(banklist),
        );

        const firstValidBank = banklist?.filter(
          (item) => item?.isRevokeAccess == true,
        );
        dispatch(
          setOrgId(
            userRole == "admin"
              ? response.data.data[0]?.orguid
              : firstValidBank[0].orguid,
          ),
        );
        dispatch(
          setOrgName(
            userRole == "admin"
              ? response.data.data[0]?.org_name
              : firstValidBank[0].org_name,
          ),
        );
        dispatch(setLoaderFalse());
      } else {
        dispatch(setLoaderFalse());
        dispatch(setOrgId(authReducer?.userData?.orguid));
        dispatch(setOrgName(authReducer?.userData?.orgnization_name));
      }
    } else {
      setBankList(authReducer?.banklist);
      sessionStorage.setItem(
        "BONDAI_NETWORK_WEB_STORAGE_SELECTED_USER_DATA",
        JSON.stringify(authReducer?.banklist),
      );
      // Geting from local
      dispatch(setLoaderFalse());
    }
  } catch (error) {
    dispatch(setLoaderFalse());
    dispatch(setOrgId(authReducer?.userData?.orguid));
    dispatch(setOrgName(authReducer?.userData?.orgnization_name));
  }
}
// do function : add bank list ek bank list
