import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogOut } from "../../../Redux/authSlice";
import { setLogoutLoading } from "../../../Redux/loaderSlice";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import moment from "moment";

const RecursiveCall = () => {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const [isPrompted, setIsPrompted] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        moment(authReducer?.expirationTime).diff(moment(), "seconds") < 0 &&
        !isPrompted
      ) {
        setVisible(true);
        setIsPrompted(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      {/* Testing session timeout */}
      <div className="card flex justify-content-center">
        <Modal
          className="emailCampaignPagePopup"
          show={visible}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
            dispatch(setLogoutLoading(true));
            dispatch(LogOut());
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="px-6 pt-6 border-bottom-0" closeButton>
            <h5 className="modal-title custompopup blockTitle mb-4">
            Session Timeout
            </h5>
          </Modal.Header>

          <Modal.Body className="px-6 pt-0">
            <p className="m-0 mb-10">
              For your security, your session has expired due to inactivity. To continue, please log in again.
            </p>
            <Button
              label="OK"
              className="rounded"
              onClick={() => {
                setVisible(false);
                dispatch(setLogoutLoading(true));
                dispatch(LogOut());
              }}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default RecursiveCall;
