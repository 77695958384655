/* eslint-disable */
import debounce from "lodash.debounce";
import {
  REGEX_PATTERN_EMAIL,
  REGEX_PATTERN_URL,
  REGEX_PATTERN_WHITESPACE,
  THUMBNAIL_TYPE,
} from "./global";
import lang from "../assets/i18n/en.json";
import exportimages from "../assets/images/exportImages";

const equalsIgnoreCase = (string1, string2) => {
  if (
    undefined === string1 ||
    null === string1 ||
    undefined === string2 ||
    null === string2
  ) {
    return false;
  }
  if (
    string1.toString().trim().toUpperCase() ===
    string2.toString().trim().toUpperCase()
  ) {
    return true;
  }
  return false;
};
export const clearAndLogout = debounce((name) => sessionTimeOut(), 1000);
const sessionTimeOut = () => {
  window.alert("Your session has expired. Please sign in again.");
  sessionStorage.clear();
  let currentURL = window.location.href.split("/");
  if (currentURL[3] === "admin") {
    window.location = "/admin-signin";
  } else {
    window.location = "/signin";
  }
};
export function addCommas(number) {
  if (number == null || isNaN(number)) {
    return "0"; // Return an empty string or handle undefined cases
  }

  const numberString = number.toFixed(2); // Ensure 2 decimal places
  const parts = numberString.split(".");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Remove trailing zeros and decimal point if unnecessary
  return parts[1] === "00" ? parts[0] : parts.join(".");
}
const matchSpecialCharacters = (string) => {
  let pattern = /^[\w\s&.\-]*$/;

  if (string.match(pattern)) {
    return false;
  }

  return true;
};
const convertLocalDateToUTCDate = (date) => {
  let utcDateTime = "";

  utcDateTime = new Date(date).toISOString();

  return utcDateTime;
};
// export const validateEmail = (email) => {
//   return !email.match(
//     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//   );
// };
export const getTimestamp = () => {
  return Date.now();
};
export function convertToTitleCase(input) {
  // Split the input string into words
  const words = input.split(" ");

  // Capitalize the first letter of each word
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase();
  });

  // Join the words back together
  const titleCaseString = titleCaseWords.join(" ");

  return titleCaseString;
}
export const validateEmail = (email, errors, setError) => {
  /** VALIDATING EMAIL  */
  let checkWorkEmail = email.toLowerCase();
  const regex = REGEX_PATTERN_EMAIL();
  const whiteSpace = REGEX_PATTERN_WHITESPACE();
  // let workMail = !(
  //   checkWorkEmail.includes("gmail") ||
  //   checkWorkEmail.includes("yahoo") ||
  //   checkWorkEmail.includes("outlook") ||
  //   checkWorkEmail.includes("hotmail") ||
  //   checkWorkEmail.includes("aol")
  // );
  if (
    regex.test(email) &&
    email.match(whiteSpace) &&
    // workMail &&
    email.length < 500
  ) {
    setError((errors) => ({
      ...errors,
      email: false,
      emailError: "",
    }));
  } else if (email.length > 500) {
    setError((errors) => ({
      ...errors,
      email: true,
      emailError: lang?.PAGE?.AUTH?.SIGNUP?.EMAIL?.MAX_LENGTH,
    }));
  } else {
    setError((errors) => ({
      ...errors,
      email: true,
      emailError: "Must enter a valid work email address.",
    }));
  }
};

export function stringToHex(str) {
  let hex = "";
  for (let i = 0; i < str.length; i++) {
    let charCode = str.charCodeAt(i).toString(16);
    hex += ("00" + charCode).slice(-2);
  }
  return hex;
}
export function hexToString(hex) {
  let str = "";
  for (let i = 0; i < hex.length; i += 2) {
    let charCode = parseInt(hex.substr(i, 2), 16);
    str += String.fromCharCode(charCode);
  }
  return str;
}

export const validateDateTime = (value, errors, setError) => {
  /** VALIDATING Date and time  */
  const selectedDateTime = new Date(value).getTime();
  const currentDateTime = new Date().getTime();
  if (value === "") {
    setError((errors) => ({
      ...errors,
      scheduledFor: true,
      scheduledForError: "Scheduled date cannot be empty!",
    }));
  } else if (selectedDateTime < currentDateTime) {
    setError((errors) => ({
      ...errors,
      scheduledFor: true,
      scheduledForError:
        "Please select a time that is ahead of the current time.",
    }));
  } else {
    setError((errors) => ({
      ...errors,
      scheduledFor: false,
      scheduledForError: "",
    }));
  }
};

export const validateEmailTemplate = (data) => {
  if (!data?.design?.body) {
    return true;
  }
  const {
    design: { body },
  } = data;
  const { rows } = body;
  let isEmpty = true;
  label: for (let i = 0; i < rows.length; i++) {
    const { columns } = rows[i];
    for (let j = 0; j < columns.length; j++) {
      const { contents } = columns[j];
      if (contents.length) {
        isEmpty = false;
        break label;
      }
    }
  }
  return isEmpty;
};

export function AmountsInThousand(number) {
  if (number != 0) {
    // number = number / 1000;
    const numberString = number.toFixed(2); // Ensure 2 decimal places
    const parts = numberString.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts[0];
  } else {
    return number;
  }
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name +
      "=;" +
      "expires=Thu, 01-Jan-1970 00:00:01 GMT;" +
      "path=" +
      "/;" +
      "domain=" +
      window.location.host +
      ";" +
      "secure=;";
  }
}

export const domainName = (url) =>
  url.replace(/^https?:\/\//, "").replace(/^www\./, "");

export const fetchCurrentCachedState = (cachedQueries) => {
  let result = null;
  cachedQueries &&
    Object.values(cachedQueries).forEach((item) => {
      if (item?.status === "fulfilled")
        result = {
          ...result,
          ...{ [item?.endpointName]: item?.data?.data },
        };
    });
  return result;
};

export const parseFirstImagefromHtml = (html) => {
  let ele = document.createElement("div");
  ele.innerHTML = html;
  const imagesArray = [];
  const imagesArrayNodeList = ele.querySelectorAll("img, div.u-row-container");
  imagesArrayNodeList.forEach((item) => {
    if (item?.src) {
      imagesArray.push({ src: item?.src, height: item?.height });
    } else {
      item?.style?.backgroundImage &&
        imagesArray.push({
          src: item?.style?.backgroundImage?.slice(4, -1).replace(/"/g, ""),
          height: item?.height ? item?.height : "auto",
        });
    }
  });
  imagesArray.sort((a, b) => b.height - a.height);
  const prompts = ["data:", "unlayer", "oaidalleapiprodscus"];
  const image = imagesArray?.find((imgURL) =>
    prompts?.some((promptName) => imgURL?.src?.indexOf(promptName) !== -1),
  );
  const imageURL = image
    ? image?.src?.match(/https/g)?.length > 1
      ? image?.src?.slice(image?.lastIndexOf("https"))
      : image?.src
    : null;
  const decodedURL =
    imageURL && imageURL?.match(/%22/g)?.length > 0
      ? imageURL?.slice(0, -4)
      : imageURL;
  return decodedURL;
};

export const checkImage = (template) => {
  const base_url = process.env.REACT_APP_CAMPAIGN_THUMBNAIL_URL;
  const url = `${base_url}${template?.templateThumbnail[0]?.url}`;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(true); // Image is valid
    };
    img.onerror = () => {
      resolve(false); // Image is invalid
    };
    img.src = url;
  });
};

export const getThumbnailImage = (isValid, item) => {
  const base_url = process.env.REACT_APP_CAMPAIGN_THUMBNAIL_URL;
  if (isValid) {
    return {
      image: `${base_url}${item?.templateThumbnail[0]?.url}`,
      alt: item?.name,
    };
  } else {
    return {
      image: exportimages?.no_image,
      defaultType: true,
      alt: item?.name,
    };
  }
};

export const formatToDollar = (value) => {
  let dollarUSLocale = Intl.NumberFormat("en-US");
  return dollarUSLocale.format(Math.round(Number(value)));
};

export const getDifferenceMessage = (trend, trend_dollar) => {
  let message = "";
  if (trend > 0) {
    if (trend_dollar) {
      message += `Increase from last quarter by $${trend_dollar}.`;
    } else {
      message += `Increase from last quarter.`;
    }
  } else if (trend < 0) {
    if (trend_dollar) {
      message += `Decrease from last quarter by $${trend_dollar}.`;
    } else {
      message += `Decrease from last quarter.`;
    }
  } else if (trend == 0) {
    if (trend_dollar) {
      message += `Same as last quarter as $${trend_dollar}.`;
    } else {
      message += `Same as last quarter.`;
    }
  }
  return message;
};

export const getDifferenceClass = (trend, is_reverse) => {
  let classString = "";
  if (is_reverse === "true") {
    if (trend > 0) {
      classString += "red-color upward-arrow-red";
    } else if (trend < 0) {
      classString += "green-color downward-arrow-green";
    } else {
      classString += "orange-color";
    }
  } else if (is_reverse === "false") {
    if (trend > 0) {
      classString += "green-color upward-arrow-green";
    } else if (trend < 0) {
      classString += "red-color downward-arrow-red";
    } else {
      classString += "orange-color";
    }
  }
  return classString;
};

export const convertMappingResponse = (payload) => {
  const mappedResponse = {};
  for (const [key, value] of Object.entries(payload)) {
    if (typeof value === "object" && !(value instanceof Array)) {
      const result = Object.entries(value).map(([key, value]) => {
        return { key, ...value };
      });
      mappedResponse[key] = result;
    } else {
      mappedResponse[key] = value;
    }
  }
  return mappedResponse;
};

export function getRangeCategory(value) {
  if (value >= 1 && value <= 40) {
    return "requires action";
  } else if (value >= 41 && value <= 65) {
    return "needs attention";
  } else if (value >= 66 && value <= 85) {
    return "good";
  } else if (value >= 86 && value <= 100) {
    return "optimal";
  }
}

export const minDateInCalander = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  return new Date(year + "-" + month + "-" + day);
};

export function calculatePercentage(data) {
  const percentCalculate = data?.current !==0 ?((data?.forecasted - data?.current) / data?.current * 100):0
  return Number(percentCalculate.toFixed(2))
}

export function calculateTrendPercentage(groupedResult) {
  return {
    all_segments: groupedResult?.all_segments?.map((data) => ({...data, key: data.key, forecasted_trend: calculatePercentage(data) })),
    segment_influencing: groupedResult?.segment_influencing?.map((data) => ({...data, key: data.key, forcasted_trend: calculatePercentage(data) })),
  };
}