import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { putToServer } from "../utils/putToServer";
import { setCookie } from "../utils/Utils";
import moment from "moment/moment";
import { _get, _post } from "../api/apiClient";
import { HttpStatusCode } from "axios";
import { CustomError } from "../api/CustomError";
import { CustomSuccess } from "../api/CustomSuccess";
import { API_STATUS } from "../rtk-query/features/sliceUtil";
import store from "../app/store";
import { setLoaderFalse, setLoaderTrue } from "./loaderSlice";

const initialState = {
  userData: sessionStorage.getItem("userData")
    ? JSON.parse(sessionStorage.getItem("userData"))
    : {},
  isLogin: sessionStorage.getItem("isLogin") ? true : false,
  token: sessionStorage.getItem("token") ?? null,
  expiresIn: sessionStorage.getItem("expiresIn") ?? null,
  expirationTime: sessionStorage.getItem("expirationTime") ?? null,
  createdOn: sessionStorage.getItem("createdOn") ?? null,
  isTempPro: sessionStorage.getItem("isTempPro") ?? null,
  banklist: [],
  userContactedSales: false,
  isLoading: API_STATUS.STATUS_IDLE,
};

const _refreshToken = () => {
  const state = store.getState();
  return state?.authReducer?.userData?.refresh_Token;
};

export const LogOut = createAsyncThunk("/auth/logout", async (__, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoaderTrue());
    const config = {
      successMessage: "Login Success!",
      errorMessage: "Login Error!",
      disableToast: true,
    };
    const response = await _get(
      `/auth/logout?refresh_token=${_refreshToken()}`,
    );
    if (response?.statusCode !== HttpStatusCode.Ok) {
      throw CustomError(response, config);
    }
    CustomSuccess(response, {
      ...config,
    });
    thunkAPI.dispatch(setLoaderFalse());
    return null;
  } catch (err) {
    if (disableLoader) thunkAPI.dispatch(setLoaderFalse());
    return thunkAPI.rejectWithValue(err);
  }
});

export const LoginThunk = createAsyncThunk(
  "auth/Login",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Login Success!",
        errorMessage: "Login Error!",
      };
      const response = await _post(`/auth/login`, data);

      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      const _response = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });
      thunkAPI.dispatch(setLoaderFalse());
      return _response;
    } catch (err) {
      if (disableLoader) thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    login: (state, action) => {
      state.userData = action.payload;
      state.isLogin = true;
      state.token = action.payload?.token;
      state.expiresIn = action.payload?.expiresIn;
      state.expirationTime = moment()
        .add(action.payload?.expiresIn, "seconds")
        .format();
      state.createdOn = action.payload?.createdOn;
      sessionStorage.setItem("userData", JSON.stringify(state.userData));
      sessionStorage.setItem("isLogin", state.isLogin);
      sessionStorage.setItem("token", state.token);
      sessionStorage.setItem("expiresIn", state.expiresIn);
      sessionStorage.setItem("expirationTime", state.expirationTime);
      sessionStorage.setItem("createdOn", state.createdOn);
      setCookie("isLogin", state.isLogin, 1);
    },
    setIsTempPro: (state, action) => {
      state.isTempPro = action.payload;
    },
    setFirstName: (state, action) => {
      state.userData.firstName = action.payload;
      sessionStorage.setItem("userData", JSON.stringify(state.userData));
    },
    setLastName: (state, action) => {
      state.userData.lastName = action.payload;
      sessionStorage.setItem("userData", JSON.stringify(state.userData));
    },
    setBankList: (state, action) => {
      state.banklist = action.payload;
    },
    logOut: (state) => {
      state.userData = {};
      state.isLogin = false;
    },
    setUserContactedSales: (state, action) => {
      state.userContactedSales = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LogOut.pending, (state, action) => {})
      .addCase(LogOut.fulfilled, (state, action) => {
        sessionStorage.clear();
        state.userData = {};
        state.isLogin = false;
        state.token = null;
      })
      .addCase(LogOut.rejected, (state, action) => {
        sessionStorage.clear();
        state.userData = {};
        state.isLogin = false;
        state.token = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  logOut,
  setIsTempPro,
  setFirstName,
  setLastName,
  setBankList,
  setUserContactedSales,
} = authSlice.actions;

export default authSlice.reducer;

// login: (state, action) => {
//   debugger;
//   state.userData = action.payload;
//   state.isLogin = true;
//   state.token = action.payload?.token;
//   state.expiresIn = action.payload?.expiresIn;
//   state.expirationTime = moment()
//     .add(action.payload?.expiresIn, "seconds")
//     .format();
//   state.createdOn = action.payload?.createdOn;
//   sessionStorage.setItem("userData", JSON.stringify(state.userData));
//   sessionStorage.setItem("isLogin", state.isLogin);
//   sessionStorage.setItem("token", state.token);
//   sessionStorage.setItem("expiresIn", state.expiresIn);
//   sessionStorage.setItem("expirationTime", state.expirationTime);
//   sessionStorage.setItem("createdOn", state.createdOn);
//   setCookie("isLogin", state.isLogin, 1);
// },
