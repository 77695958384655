import axios from "axios";
import store from "../app/store";

const BASE_URL = process.env.REACT_APP_API_URL || "https://api.example.com";

// const token = () => {
//   const state = store.getState();
//   return `${state?.authReducer?.userData?.tokenType?.toLowerCase()} ${state?.authReducer?.userData?.token}`;
// };

// function getToken() {
//   return "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlRYX21MTlVtNGg3TnhHRlVKMVcyTCJ9.eyJpc3MiOiJodHRwczovL2Rldi1ib25kYWkudXMuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDY3NDZlM2NlYmU0ZWM0MTU5YjdhY2M3NiIsImF1ZCI6WyJodHRwczovL2Rldi1ib25kYWkudXMuYXV0aDAuY29tL2FwaS92Mi8iLCJodHRwczovL2Rldi1ib25kYWkudXMuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTczNDAwODU2NSwiZXhwIjoxNzM0MDk0NTY1LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIHJlYWQ6Y3VycmVudF91c2VyIHVwZGF0ZTpjdXJyZW50X3VzZXJfbWV0YWRhdGEgZGVsZXRlOmN1cnJlbnRfdXNlcl9tZXRhZGF0YSBjcmVhdGU6Y3VycmVudF91c2VyX21ldGFkYXRhIGNyZWF0ZTpjdXJyZW50X3VzZXJfZGV2aWNlX2NyZWRlbnRpYWxzIGRlbGV0ZTpjdXJyZW50X3VzZXJfZGV2aWNlX2NyZWRlbnRpYWxzIHVwZGF0ZTpjdXJyZW50X3VzZXJfaWRlbnRpdGllcyBvZmZsaW5lX2FjY2VzcyIsImd0eSI6InBhc3N3b3JkIiwiYXpwIjoiaTB1Rk1BVzd5NGZJdG9CUDRyYUpxOWhyb3JGN2xWYlAifQ.WwtdLEJQ-fd8DWnCWRGKZFi8BcMpnEbD_UuPTYFuezz2p_44Zzux8xooAi3zf4R1QLR-OyYPTUGCnjwhGZOicYljI2A3GsN-aTC6hpqFUoulRYQvWbuxyqQ-_FBJmUNqRo9b3B5T_jHBsXhPgJbLTxToHK4YL8Up3OheY5A8bvEfYLcb4XEc0dU0Z_UB2ToxwSJY2-ALYIIYBGagxWd-pibVNLfqYyHwvD6j3hPf9x3IMMuJ8UjKP-TZ1iXY6Lh-UNNUCHJs-j145I8qfHdbO6ur-lAUIiBSkUHPzf0L7CtwOb9Ma1NCsMLIs4iVTZHkInqSBBD28YeTr0XITTYCMw";
// }

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${getToken()}`,
    // You can add other headers like authorization token here
  },
});

apiClient.interceptors.request.use(function (config) {
  const token = store.getState()?.authReducer?.userData?.token;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Define common API methods
const _get = async (url) => {
  return apiClient
    .get(url)
    .then((response) => {
      return {
        ...response,
        statusCode: response?.status,
        data: {
          ...response?.data,
          statusCode: response?.status,
        },
      };
    })
    .catch((error) => {
      return error?.response?.data?.error
        ? { ...error?.response?.data, statusCode: error?.response?.status }
        : {
            data: error?.response?.data,
            error: error?.code,
            message: error?.message,
            status: error?.response?.statusText,
            statusCode: error?.response?.status,
          };
    });
};

const _post = async (url, data = {}) => {
  return apiClient
    .post(url, data)
    .then((response) => {
      return {
        ...response,
        statusCode: response?.status,
        data: {
          ...response?.data,
          statusCode: response?.status,
        },
      };
    })
    .catch((error) => {
      return error?.response?.data?.error
        ? { ...error?.response?.data, statusCode: error?.response?.status }
        : {
            data: error?.response?.data,
            error: error?.code,
            message: error?.message,
            status: error?.response?.statusText,
            statusCode: error?.response?.status,
          };
    });
};

const _put = async (url, data = {}) => {
  return apiClient
    .put(url, data)
    .then((response) => {
      return {
        ...response,
        statusCode: response?.status,
        data: {
          ...response?.data,
          statusCode: response?.status,
        },
      };
    })
    .catch((error) => {
      return error?.response?.data?.error
        ? { ...error?.response?.data, statusCode: error?.response?.status }
        : {
            data: error?.response?.data,
            error: error?.code,
            message: error?.message,
            status: error?.response?.statusText,
            statusCode: error?.response?.status,
          };
    });
};

const _delete = async (url) => {
  return apiClient
    .delete(url)
    .then((response) => {
      return {
        ...response,
        statusCode: response?.status,
        data: {
          ...response?.data,
          statusCode: response?.status,
        },
      };
    })
    .catch((error) => {
      return error?.response?.data?.error
        ? { ...error?.response?.data, statusCode: error?.response?.status }
        : {
            data: error?.response?.data,
            error: error?.code,
            message: error?.message,
            status: error?.response?.statusText,
            statusCode: error?.response?.status,
          };
    });
};

export { _get, _post, _put, _delete };
