import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _get } from "../../../api/apiClient";
import { HttpStatusCode } from "axios";
import { API_STATUS } from "../sliceUtil";
import { CustomError } from "../../../api/CustomError";
import { setLoaderFalse, setLoaderTrue } from "../../../Redux/loaderSlice";
import { CustomSuccess } from "../../../api/CustomSuccess";
import store from "../../../app/store";
import { updateIdrssd } from "../infiniteBankPerformanceMetricsSlice/infiniteBankPerformanceMetricsSlice";

const initBankPerformanceMerticsState = {
  bankPerformanceMerticsData: [],
  idrssd: null,
  selectedBankMetricData: {},
  status: false,
};

const _orgClientId = () => {
  const state = store.getState();

  return state?.orgReducer?.selectedFI?.orgClientId;
};

// Helper Functions for Reuse
const fnFhsData = (data) => {
  const groupedData = {};
  const ungroupedData = {};
  const financialInstituteData = {};
  const keysToIncludeUngroupedData = [
    "net_interest_income",
    "total_loans",
    "total_deposits",
  ];

  for (const key in data) {
    if (data[key]?.group !== undefined && data[key]?.order !== undefined) {
      groupedData[key] = data[key];

      if (keysToIncludeUngroupedData?.includes(data[key]?.key)) {
        ungroupedData[key] = data[key];
      }
    } else {
      if (key === "financial_institute") {
        financialInstituteData[key] = data[key];
      } else {
        ungroupedData[key] = data[key];
      }
    }
  }
  return {
    ...financialInstituteData,
    groupedData: Object?.values(groupedData),
    ungroupedData: ungroupedData,
  };
};

const separateGrouping = (data) => {
  const groupMapping = {
    1: "segment_influencing",
    2: "all_segments",
  };
  return data.reduce((acc, obj) => {
    const key = groupMapping[obj?.group];
    const curGroup = acc[key] ?? [];
    return { ...acc, [key]: [...curGroup, obj] };
  }, {});
};

const calculatePercentage = (data) => {
  // Check if current is valid and non-zero to avoid division by zero
  if (!data?.current || data?.current === 0) {
    return 0;
  }

  const percentCalculate =
    ((data?.forecasted - data?.current) / data?.current) * 100;

  return isFinite(percentCalculate) ? Number(percentCalculate.toFixed(2)) : 0;
};

// const calculatePercentageForAutopilot = (data) => {
//   const percentCalculate =
//     ((data?.ap_used - data?.current) / data?.current) * 100;
//   return Number(percentCalculate.toFixed(2));
// };

// const calculateTrendPercentage = (groupedResult) => ({
//   all_segments: groupedResult?.all_segments?.map((data) => ({
//     ...data,
//     key: data.key,
//     forecasted_trend: calculatePercentage(data),
//     apused_trend: calculatePercentageForAutopilot(data),
//   })),
//   segment_influencing: groupedResult?.segment_influencing?.map((data) => ({
//     ...data,
//     key: data.key,
//     forcasted_trend: calculatePercentage(data),
//   })),
// });

const calculateTrendPercentageForUngroupedData = (data) => {
  const obj = {};
  for (const key in data) {
    if (typeof data[key] === "object" && !Array.isArray(data[key])) {
      obj[key] = {
        ...data[key],
        forcasted_trend: calculatePercentage(data[key]),
      };
    }
  }
  return { ...data, ...obj };
};

// const transformArray = (array) =>
//   array?.data?.data?.records?.map((item) => {
//     const result = fnFhsData(item);
//     const groupedResult = separateGrouping(result?.groupedData);

//     const calculatedPercentageResultForUngroupedData =
//       calculateTrendPercentageForUngroupedData(result?.ungroupedData);

//     const { groupedData, ungroupedData, financial_institute, ...rest } = result;

//     return {
//       ...financial_institute,
//       groupedResult: groupedResult,
//       ungroupedResult: calculatedPercentageResultForUngroupedData,
//     };
//   });
export const fetchBankPerformanceMerticsData = createAsyncThunk(
  "bankPerformanceMertics",

  async ({ paginationObj }, thunkAPI) => {
    try {
      if (paginationObj?.currentPage === 1) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched data successfully.",
        errorMessage: "Failed to fetch data!",
      };

      const _pageNumber = paginationObj?.currentPage
        ? `page=${paginationObj?.currentPage}`
        : "";
      const _pageSize =
        paginationObj?.limit > 0 ? `&size=${paginationObj?.limit}` : "";
      const _searchBy = paginationObj?.searchData
        ? `&searchby=${paginationObj?.searchData}`
        : "";
      const response = await _get(
        `bank/${_orgClientId()}/performance?${_pageNumber}${_pageSize}${_searchBy}`,
      );

      if (response?.data?.statusCode !== HttpStatusCode.Ok) {
        throw new Error(CustomError(response, config)?.message);
      }

      const bankPerformanceMertics = {
        totalRecords: response?.data?.data?.total_records,
        bankPerformanceMerticsData: transformArray(response),
      };
      function separateGrouping(data) {
        const groups = data.reduce((acc, obj) => {
          const key = groupMapping[obj?.group];
          const curGroup = acc[key] ?? [];
          return { ...acc, [key]: [...curGroup, obj] };
        }, {});
        return groups;
      }

      function calculateTrendPercentageForUngroupedData(data) {
        const obj = {};
        for (const key in data) {
          if (typeof data[key] === "object" && !Array.isArray(data[key])) {
            obj[key] = {
              ...data[key],
              forcasted_trend: calculatePercentage(data[key]),
            };
          }
        }
        return { ...data, ...obj };
      }

      // function transformArray(array) {
      //   return array?.data?.data?.records?.map((item) => {
      //     const result = fnFhsData(item);
      //     const groupedResult = separateGrouping(result?.groupedData);
      //     console.log("--->groupedResult",groupedResult)
      //     const calculatedPercentageResult = calculateTrendPercentage(groupedResult)
      //     const calculatedPercentageResultForUngroupedData = calculateTrendPercentageForUngroupedData(result?.ungroupedData)

      //     const { groupedData, ungroupedData, financial_institute, ...rest } =
      //       result;

      //     return {
      //       ...financial_institute,
      //       groupedResult: groupedResult,
      //       ungroupedResult: calculatedPercentageResultForUngroupedData,
      //     };
      //   });
      // }

      const { data, error, message, status, statusCode, responseType } =
        CustomSuccess(response, {
          ...config,
          disableToast: true,
        });

      thunkAPI.dispatch(setLoaderFalse());
      return {
        bankPerformanceMertics,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error);
    }
  },
);

// export const getFHSDataByIdrssd = createAsyncThunk(
//   "bankPerformanceMertics",

//   async ({ idrssd }, thunkAPI) => {
//     try {
//       // debugger
//       thunkAPI.dispatch(setLoaderTrue());
//       const config = {
//         successMessage: "Fetched data successfully.",
//         errorMessage: "Failed to fetch data!",
//       };

//       const _idrssd = `${idrssd}`;
//       // const _pageSize = `&size=${100}`;

//       const response = await _get(
//         `bank/${_orgClientId()}/performance/${_idrssd}`,
//       );

//       if (response?.data?.statusCode !== HttpStatusCode.Ok) {
//         throw new Error(CustomError(response, config)?.message);
//       }

//       const FIData = transformArray(response);

//       const { data, error, message, status, statusCode, responseType } =
//         CustomSuccess(response, {
//           ...config,
//           disableToast: true,
//         });

//       thunkAPI.dispatch(setLoaderFalse());
//       return {
//         FIData,
//         error,
//         message,
//         status,
//         statusCode,
//         responseType,
//       };
//     } catch (error) {
//       thunkAPI.dispatch(setLoaderFalse());
//       return thunkAPI.rejectWithValue(error);
//     }
//   },
// );
export const getFHSStatusByIdrssd = createAsyncThunk(
  "bankPerformanceMertics",

  async ({ idrssd }, thunkAPI) => {
    try {
      // debugger
      thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched status successfully.",
        errorMessage: "Failed to fetch status!",
      };

      const _idrssd = `${idrssd}`;
      // const _pageSize = `&size=${100}`;

      const response = await _get(
        `bank/${_orgClientId()}/performance/${_idrssd}/status`,
      );

      if (response?.data?.statusCode !== HttpStatusCode.Ok) {
        throw new Error(CustomError(response, config)?.message);
      }

      const FIStatus = response?.data;

      const { data, error, message, status, statusCode, responseType } =
        CustomSuccess(response, {
          ...config,
          disableToast: true,
        });

      thunkAPI.dispatch(setLoaderFalse());
      return {
        FIStatus,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const bankPerformanceMerticsSlice = createSlice({
  name: "bankPerformanceMertics",
  initialState: initBankPerformanceMerticsState,
  reducers: {
    // New reducer to update the idrssd property
    updateIdrssd(state, action) {
      state.idrssd = action.payload;
    },
    setSelectedBankMetricData(state, action) {
      state.selectedBankMetricData = action.payload;
    },
    resetBankPerformanceMerticsData(state) {
      state.bankPerformanceMerticsData = [];
    },
  },
  extraReducers: (builder) => {
    //Handle fetchBankPerformanceMerticsData
    builder
      .addCase(fetchBankPerformanceMerticsData.pending, () => ({
        isLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(fetchBankPerformanceMerticsData.fulfilled, (_state, action) => {
        console.log(action);
        return {
          // isLoading: API_STATUS.STATUS_SUCCESS,
          bankPerformanceMerticsData: action?.payload?.bankPerformanceMertics,
          // totalEmailTemplates: action?.payload?.emailTemplates?.totalCount,
        };
      })
      .addCase(fetchBankPerformanceMerticsData.rejected, (_state, action) => {
        return {
          isLoading: API_STATUS.STATUS_FAILURE,
          message: action.error.message || "Error while fetching data",
          bankPerformanceMertics: [],
        };
      });
  },
});

// Export the new action
export const {} = bankPerformanceMerticsSlice.actions;
