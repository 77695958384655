import moment from "moment";
import exportimages from "../../../assets/images/exportImages";

export const EMAIL_CAMPAIGN_ORG_ID =
  "8031c09d-bbfd-8d4c-e3d1-59b3484e7999::undefined";
// export const EMAIL_CAMPAIGN_ORG_ID = "33309668-45ff-7248-ef06-8d4890fd7206";
// export const EMAIL_CAMPAIGN_ORG_ID = "82633550-2b75-e8b2-8c35-f152dc8e503f";

export const EMAIL_CAMPAIGN_TEMPLATE_ID =
  "MWQ3ODVkMGQtZWFkOC03ZWQwLTFmOGUtOTdjMzNlZDI3MzdhOg==";
export const TEST_EMAIL_ARRAY = [
  {
    label: "kent@bond.ai",
    value: "kent@bond.ai",
  },
  {
    label: "jeff.norton@bond.ai",
    value: "jeff.norton@bond.ai",
  },
  {
    label: "bryce.mildon@bond.ai",
    value: "bryce.mildon@bond.ai",
  },
  {
    label: "anjali@bond.ai",
    value: "anjali@bond.ai",
  },
  {
    label: "pooja@bond.ai",
    value: "pooja@bond.ai",
  },
  {
    label: "varun@bond.ai",
    value: "varun@bond.ai",
  },
  {
    label: "sneha@bond.ai",
    value: "sneha@bond.ai",
  },
  {
    label: "jugal.baraiya@bond.ai",
    value: "jugal.baraiya@bond.ai",
  },
];

export const getFormattedDate = (date) => {
  const isoDate = date;
  const dateObject = new Date(isoDate);

  // Extract individual components for custom formatting
  const month = dateObject.toLocaleString("en-US", {
    month: "short",
  });
  const day = dateObject.getDate();
  let year = Number(dateObject.getFullYear());
  // const time = dateObject.toLocaleString("en-US", {
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  // });

  // Combine the components into the desired format
  const finalFormat = `${month} ${day},  ${year}`;
  return finalFormat;
};

export const getFormattedDateType1 = (date) => {
  const dateObject = new Date(date);
  let day = String(dateObject.getDate()).padStart(2, "0");
  var month = dateObject.toLocaleString("default", {
    month: "short",
  });
  let year = dateObject.getFullYear().toString().substring(2);
  var hrs = dateObject.getHours();
  var mnts = dateObject.getMinutes();
  var AMPM = hrs >= 12 ? "PM" : "AM";
  hrs = hrs % 12;
  hrs = hrs ? hrs : 12;
  mnts = mnts < 10 ? "0" + mnts : mnts;
  var time = hrs + ":" + mnts + " " + AMPM;
  return (
    <span className="ps-0 ps-xxl-0">
      {" "}
      {month} {day}, '{year}, {time}{" "}
    </span>
  );
};

export const getMinuteDifference = (data) => {
  const scheduledTime = moment.utc(data?.scheduled_On);
  const currentTime = moment.utc();
  const diffInMinutes = currentTime.diff(scheduledTime, "minutes");
  return diffInMinutes < -10;
};

export const EMAIL_TEMPLATE_STATUS = {
  DRAFT_TEMPLATE: "bondai_campaign_template_status_draft",
  ACTIVE_TEMPLATE: "bondai_campaign_template_status_active",
  INACTIVE_TEMPLATE: "bondai_campaign_template_status_inactive",
};

export const EMAIL_TEMPLATE_TYPE = {
  BOND_CREATED: "bondai_campaign_template_type_bond_created",
  CLIENT_CREATED: "bondai_campaign_template_type_client_created",
};

export const EMAIL_CAMPAIGN_STATUS = {
  DRAFT_CAMPAIGN: "bondai_campaign_status_draft",
  PENDING_CAMPAIGN: "bondai_campaign_status_pending",
  SCHEDULE_CAMPAIGN: "bondai_campaign_status_scheduled",
  QUEUE_CAMPAIGN: "bondai_campaign_status_queued",
  ACTIVE_CAMPAIGN: "bondai_campaign_status_active",
  COMPLETE_CAMPAIGN: "bondai_campaign_status_completed",
  DELETE_CAMPAIGN: "bondai_campaign_status_deleted",
  CANCEL_CAMPAIGN: "bondai_campaign_status_cancelled",
  PAUSED_CAMPAIGN: "bondai_campaign_status_paused",
};

export const DISPLAY_EMAIL_CAMPAIGN_STATUS = [
  {
    key: EMAIL_CAMPAIGN_STATUS.DRAFT_CAMPAIGN,
    club: [EMAIL_CAMPAIGN_STATUS.DRAFT_CAMPAIGN],
    label: "Campaign in Draft",
    icon: exportimages?.icon_drafted_capmaign,
    isVisible: true,
    status_circle_color: "#FFAB51",
    status_badge_background: "#FFF3E6",
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.PENDING_CAMPAIGN,
    club: [EMAIL_CAMPAIGN_STATUS.PENDING_CAMPAIGN],
    label: "Campaign is Pending Approval",
    icon: exportimages?.icon_approved_campaign,
    isVisible: false,
    // status_circle_color: "#00D2A3",
    // status_badge_background: "#EFFDFB",
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.SCHEDULE_CAMPAIGN,
    club: [EMAIL_CAMPAIGN_STATUS.SCHEDULE_CAMPAIGN],
    label: "Campaign is Scheduled",
    icon: exportimages?.icon_reviews_pending,
    isVisible: false,
    // status_circle_color: "#00D2A3",
    // status_badge_background: "#EFFDFB",
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.SCHEDULE_CAMPAIGN,
    club: [
      EMAIL_CAMPAIGN_STATUS.SCHEDULE_CAMPAIGN,
      EMAIL_CAMPAIGN_STATUS.QUEUE_CAMPAIGN,
    ],
    label: "Scheduled/Queued",
    icon: exportimages?.icon_reviews_pending,
    isVisible: true,
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.QUEUE_CAMPAIGN,
    club: [EMAIL_CAMPAIGN_STATUS.QUEUE_CAMPAIGN],
    label: "Campaign is Queued with Provider",
    icon: exportimages?.icon_ongoing_campaign,
    isVisible: false,
    // status_circle_color: "#00D2A3",
    // status_badge_background: "#EFFDFB",
  },

  {
    key: EMAIL_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN,
    club: [EMAIL_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN],
    label: "Campaign is Active/Live",
    icon: exportimages?.icon_ongoing_campaign,
    isVisible: true,
    status_circle_color: "#00D2A3",
    status_badge_background: "#EFFDFB",
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.COMPLETE_CAMPAIGN,
    club: [EMAIL_CAMPAIGN_STATUS.COMPLETE_CAMPAIGN],
    label: "Campaign is Completed",
    icon: exportimages?.icon_completed_campaign,
    isVisible: true,
    // status_circle_color: "#00D2A3",
    // status_badge_background: "#EFFDFB",
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.DELETE_CAMPAIGN,
    club: [EMAIL_CAMPAIGN_STATUS.DELETE_CAMPAIGN],
    label: "Campaign is Deleted",
    icon: exportimages?.icon_deleted_campaign,
    isVisible: false,
    // status_circle_color: "#00D2A3",
    // status_badge_background: "#EFFDFB",
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.CANCEL_CAMPAIGN,
    club: [EMAIL_CAMPAIGN_STATUS.CANCEL_CAMPAIGN],
    label: "Campaign is Cancelled",
    icon: exportimages?.icon_deleted_campaign,
    isVisible: false,
    // status_circle_color: "#00D2A3",
    // status_badge_background: "#EFFDFB",
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.PAUSED_CAMPAIGN,
    club: [EMAIL_CAMPAIGN_STATUS.PAUSED_CAMPAIGN],
    label: "Paused",
    icon: exportimages?.icon_deleted_campaign,
    isVisible: false,
    // status_circle_color: "#00D2A3",
    // status_badge_background: "#EFFDFB",
  },
];

export const DISPLAY_EMAIL_CAMPAIGN_STATUS_IN_FILTER_CONFIG = [
  {
    key: EMAIL_CAMPAIGN_STATUS.DRAFT_CAMPAIGN,
    label: "Draft",
    isVisible: true,
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.PENDING_CAMPAIGN,
    label: "Pending",
    isVisible: false,
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.SCHEDULE_CAMPAIGN,
    label: "Scheduled",
    isVisible: true,
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.QUEUE_CAMPAIGN,
    label: "Queued",
    isVisible: true,
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.ACTIVE_CAMPAIGN,
    label: "Active/Live",
    isVisible: true,
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.COMPLETE_CAMPAIGN,
    label: "Completed",
    isVisible: true,
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.DELETE_CAMPAIGN,
    label: "Deleted",
    isVisible: false,
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.PAUSED_CAMPAIGN,
    label: "Paused",
    isVisible: false,
  },
  {
    key: EMAIL_CAMPAIGN_STATUS.CANCEL_CAMPAIGN,
    label: "Cancelled",
    isVisible: true,
  },
];
