import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { API_STATUS } from "../../sliceUtil";
import { _get } from "../../../../api/apiClient";
import { CustomError } from "../../../../api/CustomError";
import { CustomSuccess } from "../../../../api/CustomSuccess";

const initialConsumerListState = {
  consumerListDetails: [],
  isLoading: API_STATUS.STATUS_IDLE,
  totalConsumerList: 0,
};

const base_url = process.env.REACT_APP_API_URL;

//get all consumerlistdetails
export const fetchConsumerListDetails = createAsyncThunk(
  "consumersDetails/fetchConsumerListDetails",
  async (
    { orgClientId, campaignId, consumerListId, paginationObj },
    thunkAPI,
  ) => {
    try {
      let offset = (paginationObj?.currentPage - 1) * paginationObj?.limit;
      const config = {
        successMessage: "Fetched ConsumerDetails successfully.",
        errorMessage: "Failed to fetch ConsumerDetails!",
      };
      const response = await _get(
        `${base_url}/${orgClientId}/campaign${campaignId ? `/${campaignId}` : ""}/customer/${consumerListId}?searchby=${paginationObj?.searchData}&limit=${paginationObj?.limit}&offset=${offset}&orderby=${paginationObj?.sortObj?.sortOrder}&field=CREATED_DATE`
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
         throw CustomError(response, config);
      }
      const _response = CustomSuccess(response, {
        ...config,
        disableToast: true,
      });
      return _response?.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const consumerListDetailsSlice = createSlice({
  name: "consumersDetails",
  initialState: initialConsumerListState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchConsumerListDetails.pending, () => ({
        isLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(fetchConsumerListDetails.fulfilled, (_state, action) => {
        return{
        isLoading: API_STATUS.STATUS_SUCCESS,
        consumerListDetails: action.payload.customers,
        totalConsumerList: action?.payload?.totalCustomers,
      }})
      .addCase(fetchConsumerListDetails.rejected, (_state, action) => {
        return {
          isLoading: API_STATUS.STATUS_FAILURE,
          message:
            action.error.message || "Error while fetching consumerdetails",
        };
      });
  },
});

export const selectConsumerListDetails = (_state) =>
  _state?.consumerListDetails;
export const {} = consumerListDetailsSlice.actions;
