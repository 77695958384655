import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_URL || "https://api.example.com";

export const autoPilotLookupsSlice = createApi({
  reducerPath: "autopilot_lookup",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["autopilot_lookup"],
  endpoints: (build) => ({
    fetchAutopilotLookups: build.query({
      query: ({ headers }) => ({
        url: `lookup`,
        method: "GET",
        headers: headers,
      }),
      transformResponse: (response) => {
        if (response.status === "SUCCESS") {
          // grouping the AUTOPILOT_LOOKUP based on lookup-key
          const groupedAutopilotLookupByKey = response?.data?.reduce(
            (groupedLookups, lookup) => {
              const lookupKey = lookup?.lookup_Key;
              const currentGroup = groupedLookups[lookupKey] ?? [];
              return {
                ...groupedLookups,
                [lookupKey]: [
                  ...currentGroup,
                  { ...lookup, name: lookup?.display_Value, code: lookup?.key },
                ],
              };
            },
            {},
          );
          return { ...response, data: groupedAutopilotLookupByKey };
        } else {
          return { ...response, data: null };
        }
      },
    }),
  }),
});

export const { useFetchAutopilotLookupsQuery } = autoPilotLookupsSlice;
