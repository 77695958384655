import { HttpStatusCode } from "axios";
import store from "../app/store";
import { LogOut } from "../Redux/authSlice";
import { toast } from "react-toastify";
import { toastResponse } from "../Components/Common/Toast/Toast";
import MESSAGE from "../assets/i18n/en.json";

const ERRORMESSAGE = MESSAGE.ERROR;
export const CustomError = (response, config) => {
  if (config?.disableToast === true)
    return { ...response, responseType: "CustomError" };
  if (
    response?.statusCode === HttpStatusCode.Unauthorized ||
    response?.response?.status === HttpStatusCode.Unauthorized
  ) {
    toast.error(toastResponse("error", ERRORMESSAGE.CODE[403]));
    store.dispatch(LogOut());
  } else if (response?.error) {
    toast.error(
      toastResponse(
        "error",
        response.error
          ? ERRORMESSAGE.CUSTOM.CODE[response?.error] || response?.error
          : config?.errorMessage,
      ),
    );
  } else {
    toast.error(
      toastResponse(
        "error",
        response.error
          ? response.error || response?.message
          : config?.errorMessage,
      ),
    );
  }
  return { ...response, responseType: "CustomError" };
};
