import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { _get, _post, _put } from "../../../api/apiClient";
import { setLoaderFalse, setLoaderTrue } from "../../../Redux/loaderSlice";
import { CustomSuccess } from "../../../api/CustomSuccess";
import { API_STATUS } from "../sliceUtil";
import store from "../../../app/store";
import { CustomError } from "../../../api/CustomError";

const initUserManagementState = {
    userManagement: null,
    isLoading: API_STATUS.STATUS_IDLE,
};

const _orgClientId = () => {
    const state = store.getState();
    return state?.authReducer?.userData?.idrssd;
};

export const fetchUserData = createAsyncThunk(
    "user/fetchUser",
    async ({ disableLoader }, thunkAPI) => {
        try {
            if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
            const config = {
                successMessage: "Fetched User successfully.",
                errorMessage: "Failed to fetch user!",
            };
            const response = await _get(
                `/clientuser/client/${_orgClientId()}`,
            );

            if (response?.data?.statusCode !== HttpStatusCode.Ok) {
                throw CustomError(response, config);
            }
            const {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            } = CustomSuccess(response, {
                ...config,
                disableToast: true,
            });
            thunkAPI.dispatch(setLoaderFalse());
            return {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            };
        } catch (error) {
            thunkAPI.dispatch(setLoaderFalse());
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const fetchPendingUserData = createAsyncThunk(
    "pendinguser/fetchPendingUser",
    async ({ disableLoader }, thunkAPI) => {
        try {

            if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
            const config = {
                successMessage: "Fetched Pending-User successfully.",
                errorMessage: "Failed to fetch pending-user!",
            };
            const response = await _get(
                `/applicant/${_orgClientId()}`,
            );

            if (response?.data?.statusCode !== HttpStatusCode.Ok) {
                throw CustomError(response, config);
            }
            const {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            } = CustomSuccess(response, {
                ...config,
                disableToast: true,
            });
            thunkAPI.dispatch(setLoaderFalse());
            return {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            };
        } catch (error) {
            thunkAPI.dispatch(setLoaderFalse());
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const updatePendingUserData = createAsyncThunk(
    "updateuser/updatePendingUser",
    async ({ id, inviteStatus, disableLoader }, thunkAPI) => {
        try {

            if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
            const config = {
                successMessage: "Registration request is approved successfully!",
                errorMessage: "Failed to update user!",
            };
            const response = await _put(
                `/applicant/${id}/status?status=${inviteStatus}`, {}
            );

            if (response?.data?.statusCode !== HttpStatusCode.Ok) {
                throw CustomError(response, config);
            }
            const {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            } = CustomSuccess(response, {
                ...config,
                disableToast: false,
            });
            thunkAPI.dispatch(setLoaderFalse());
            return {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            };
        } catch (error) {
            thunkAPI.dispatch(setLoaderFalse());
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const fetchAllUserRole = createAsyncThunk(
    "userrole/fetchuserrole",
    async ({ disableLoader }, thunkAPI) => {
        try {

            if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
            const config = {
                successMessage: "Updated User Role successfully.",
                errorMessage: "Failed to update user role!",
            };
            const response = await _get(
                `007a1000-0000-0000-0000-000000000003/role`, {}
            );

            if (response?.data?.statusCode !== HttpStatusCode.Ok) {
                throw CustomError(response, config);
            }
            const {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            } = CustomSuccess(response, {
                ...config,
                disableToast: true,
            });
            thunkAPI.dispatch(setLoaderFalse());
            return {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            };
        } catch (error) {
            thunkAPI.dispatch(setLoaderFalse());
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const updateUserData = createAsyncThunk(
    "updateuser/updateuser",
    async ({ id, userData, disableLoader }, thunkAPI) => {
        try {
           
            if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
            const config = {
                successMessage: "User details updated successfully!",
                errorMessage: "Failed to update user details!",
            };
            const response = await _put(
                `clientuser/${id}`, userData
            );

            if (response?.data?.statusCode !== HttpStatusCode.Ok) {
                throw CustomError(response, config);
            }
            const {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            } = CustomSuccess(response, {
                ...config,
                disableToast: false,
            });
            thunkAPI.dispatch(setLoaderFalse());
            return {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            };
        } catch (error) {
            thunkAPI.dispatch(setLoaderFalse());
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const inviteUserData = createAsyncThunk(
    "inviteuser/inviteuser",
    async ({ id, userData, disableLoader }, thunkAPI) => {
        try {
            if (!disableLoader) thunkAPI.dispatch(setLoaderTrue());
            const config = {
                successMessage: "The invitation has been sent to the user successfully!",
                errorMessage: "Failed to invite user!",
            };
            const response = await _post(
                `clientuser/invite`, userData
            );

            if (response?.data?.statusCode !== HttpStatusCode.Ok) {
                throw CustomError(response, config);
            }
            const {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            } = CustomSuccess(response, {
                ...config,
                disableToast: false,
            });
            thunkAPI.dispatch(setLoaderFalse());
            return {
                data,
                error,
                message,
                status,
                statusCode,
                responseType,
            };
        } catch (error) {
            thunkAPI.dispatch(setLoaderFalse());
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const userManagementSlice = createSlice({
    name: "usermanagement",
    initialState: initUserManagementState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, () => ({
                isLoading: API_STATUS.STATUS_LOADING,
            }))
            .addCase(fetchUserData.fulfilled, (_state, action) => {
                return {
                    isLoading: API_STATUS.STATUS_SUCCESS,
                    userManagement: action.payload.data
                }
            })
            .addCase(fetchUserData.rejected, (_state, action) => {
                return {
                    isLoading: API_STATUS.STATUS_FAILURE,
                    message:
                        action.error.message || "Error while fetching users",
                };
            });
    },
});

export const selectUserManagement = (_state) => _state?.userManagement;

