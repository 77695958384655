import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import exportimages from "../../../../../assets/images/exportImages";
import { useEffect, useState } from "react";
import { fetchInvoiceDetails } from "../../../../../rtk-query/features/stripe/stripeSlice";
import { useDispatch } from "react-redux";
import downloadIcon from "../../../../../assets/images/history_download_btn.png";
import {
  setLoaderFalse,
  setLoaderTrue,
} from "../../../../../Redux/loaderSlice";

const Invoice = () => {
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState({});

  useEffect(() => {
    (async () => {
      let response = await dispatch(
        fetchInvoiceDetails({ disableLoader: false }),
      );
      setInvoiceData(response?.payload?.data?.data);
    })();
  }, [dispatch]);
  const paymentMethodBody = (rowData, index = null) => {
    if (rowData?.collection_method !== null && index !== null) {
      return (
        <>
          <div className={`processStatusBlock ps-0`}>
            {rowData?.collection_method ? rowData?.collection_method : ""}
          </div>
          {
            <div className="actionBlock">
              <div
                title="Download invoice"
                className="template-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  // setmodal(false);
                  downloadPDF(rowData?.invoice_pdf);
                }}
              >
                <img alt="icon" src={exportimages.download_pdf} />
              </div>
            </div>
          }
        </>
      );
    }
  };
  const effectiveDateBody = (rowData, index = null) => {
    return (
      <div className={`ps-0`}>
        {rowData?.effective_at
          ? formatUnixTimestamp(rowData?.effective_at)
          : ""}
      </div>
    );
  };
  const downloadPDF = async (invoice_pdf) => {
    dispatch(setLoaderTrue());
    try {
      let a = document.createElement("a");
      a.href = invoice_pdf;
      a.target = "_blank";
      a.click();
      dispatch(setLoaderFalse());
    } catch (error) {
      dispatch(setLoaderFalse());
    }
  };

  const formatUnixTimestamp = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds

    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString("en-US", options);
    const [datePart, timePart] = formattedDate.split(", "); // Split date and time
    return `${datePart.replace(/,/, "")} | ${timePart}`;
  };

  const srNumberBody = (rowData, index) => {
    return <div style={{ width: "100%" }}>{index.rowIndex + 1}</div>;
  };
  console.log(invoiceData);
  return (
    <div
      className="px-10 pt-5 pb-10 screen-container contentWrapper invoice-page dashboardContainer position-relative"
      id="invoice"
    >
      <div className="mainContainerWrapper">
        <div className="row g-0 g-xl-5 g-xxl-8">
          <div className="col-xl-12 mb-4">
            <div className="float-start">
              <div className="custom-theme pageTitle">
                <h3 className="text-dark fs-1 fw-bolder lh-lg mb-4">Billing</h3>
              </div>
            </div>
          </div>
        </div>
        {/* TABLE */}
        <div className="row g-0 g-xl-5 g-xxl-8">
          <div className="col-md-12">
            <div className="Invoice mainContainer h-100 px-2">
              <DataTable
                value={Array.isArray(invoiceData) ? invoiceData : []}
                className="p-custom-datatable InvoiceTableData sortDataTable h-100 "
                selectionMode="single"
                rows={10}
                paginator="true"
                reflow="true"
                paginatorPosition="bottom"
                rowHover="true"
                scrollable="true"
                scrollHeight="flex"
                resizableColumns="true"
                size="large"
                emptyMessage={() => (
                  <h5 className="text-center pt-4 mt-1 mb-0 w-100">
                    <div className="col-md-12 mb-8">
                      <img src={exportimages.noDataFound} alt="no data" />
                    </div>
                    <div className="col-md-12">
                      <h5>There is no data to display at the moment.</h5>
                    </div>
                  </h5>
                )}
              >
                <Column
                  header="Sr. No."
                  body={srNumberBody}
                  style={{ width: "2rem", padding: "16px" }}
                />

                <Column
                  header="Invoice"
                  field="id"
                  style={{ maxWidth: "10rem" }}
                  // sortable
                />
                <Column
                  header="Date"
                  body={effectiveDateBody}
                  field="effective_at"
                  headerClassName={"ps-5"}
                  style={{ maxWidth: "14rem" }}
                  // sortable
                />

                <Column
                  header="Status"
                  headerClassName={"ps-5"}
                  field="status"
                  style={{ maxWidth: "12rem" }}
                  // sortable
                />
                <Column
                  header="Amount"
                  headerClassName={"ps-5"}
                  field="amount_paid"
                  style={{ maxWidth: "15rem" }}
                  // sortable
                />
                <Column
                  header="Paid Method"
                  headerClassName={"ps-5"}
                  field="collection_method"
                  body={paymentMethodBody}
                  style={{ width: "15%" }}
                  bodyClassName={"processStatusMainBlock"}
                  // sortable
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Invoice;
